import React, { useEffect, useMemo, useState } from "react";
import "./lockuphistory.css";
import upDown from "../../assets/images/upDown.svg";
import prev from "../../assets/images/prev.svg";
import next from "../../assets/images/next.svg";
import start from "../../assets/images/start.svg";
import end from "../../assets/images/end.svg";
import axios from "../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

function ConvertData1(params) {
  const date = new Date(params);

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate;
}

const LockupHistory = () => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [active, setActive] = useState("ongoing");
  const [locks, setLocks] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(0)
  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "black",
        color: "white",
      },
    });
  };

  useEffect(() => {
    if (params.get("trader_email")) {
      axios
        .get(`lock/saving/support/${params.get("trader_email")}/`)
        .then((res) => {
          console.log(res);
          setLocks(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {

      axios
        .get("lock/saving")
        .then((res) => {
          console.log(res);
          setLocks(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [params, refresh]);
  const totalPages = Math.ceil(locks?.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentItems = locks?.slice(startIndex, endIndex);

  const sortedCurrentItems = currentItems.slice().sort((a, b) => {
    if (sortColumn) {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortDirection === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
      } else {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      }
    }
    return 0;
  });

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const closeSaving = async (id) => {
    setLoading(true)
    let deviceType = window.innerWidth <= 768 ? "Mobile" : window.innerWidth <= 1024 ? "Tablet" : "Desktop"
    try {
      axios.post(`lock/saving/close/${id}/`, {device_type:deviceType}).then(res => {
        console.log(res)
        setRefresh(!refresh)
        setLoading(false)
        showRequestSuccess("Saving has been closed successfully")
      }).catch(err => {
        console.log(err)
        setLoading(false)
        showRequestFailed("Failed to close saving.")
      })
    } catch (error) {
      console.log(error)
    }
  }

  const onCloseModal = () => setOpen(false);
  const closeIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path d="M9.85397 7.50067L13.8545 11.5012C14.1143 11.761 14.1143 12.183 13.8545 12.4429L12.4429 13.8545C12.183 14.1144 11.7617 14.1144 11.5012 13.8545L7.5 9.85398L3.49884 13.8545C3.23897 14.1144 2.81765 14.1144 2.55778 13.8545L1.14553 12.4429C0.885654 12.183 0.885654 11.7617 1.14553 11.5012L5.14668 7.50067L1.14553 3.49951C0.885654 3.23964 0.885654 2.81767 1.14553 2.55779L2.55844 1.1462C2.81831 0.886325 3.23962 0.886325 3.4995 1.1462L7.5 5.14736L11.5012 1.1462C11.761 0.886325 12.1823 0.886325 12.4429 1.1462L13.8545 2.55845C14.1143 2.81833 14.1143 3.23964 13.8545 3.50017L9.85397 7.50067Z" fill="#151515" />
    </svg>
  );

  return (
    <div className="lockupTableContainer">
      <ToastContainer />
      <div className="lockupTableHeader">
        <div className="lockupHeaderLeft">
          <p>Savings History</p>
        </div>

        <div className="lockupHeaderRight">
          <div className="savingOptions">
            <div
              onClick={() => setActive("all")}
              className={`${active === "all"
                  ? "active-middle-item"
                  : "nonactive-middle-item"
                }`}
            >
              <p>All</p>
            </div>
            <div
              onClick={() => setActive("ongoing")}
              className={`${active === "ongoing"
                  ? "active-middle-item"
                  : "nonactive-middle-item"
                }`}
            >
              <p>Ongoing</p>
            </div>
            <div
              onClick={() => setActive("closed")}
              className={`${active === "closed"
                  ? "active-middle-item"
                  : "nonactive-middle-item"
                }`}
            >
              <p>Completed</p>
            </div>
          </div>
        </div>
      </div>
      <div className="lockupTableWrapper">
        <table className="lockupTable" responsive hover>
          <thead>
            <tr>
              <th onClick={() => handleSort("dateAndtime")}>
                Date <img src={upDown} alt="upDown" />
              </th>
              <th onClick={() => handleSort("type")}>
                Amount <img src={upDown} alt="upDown" />
              </th>
              <th onClick={() => handleSort("channel")}>
                Duration(days) <img src={upDown} alt="upDown" />
              </th>
              <th onClick={() => handleSort("amount")}>
                Interest rate <img src={upDown} alt="upDown" />
              </th>
              <th onClick={() => handleSort("status")}>
                Status <img src={upDown} alt="upDown" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedCurrentItems
              .filter((item) => {
                if (active === "all") {
                  return item;
                } else {
                  return item.status === active;
                }
              })
              .map((row, index) => (
                <tr key={index}>
                  <td>{ConvertData1(row.start_date)}</td>
                  <td>{row.amount}</td>
                  <td>{row.duration}</td>
                  <td>{row.interest_rate}</td>
                  <td>
                    {row.status === "closed" ? (
                      <div className="bgSuccess">
                        <p>
                          {row.status.charAt(0).toUpperCase() +
                            row.status.slice(1)}
                        </p>
                      </div>
                    ) : (
                      <div className="bgPending">
                        <p>
                          {row.status.charAt(0).toUpperCase() +
                            row.status.slice(1)}
                        </p>
                      </div>
                    )}
                  </td>
                  <td>
                    {active !== "closed" && row.status !== "closed" && !params.get("trader_email") && <button onClick={() => {
                      setId(row.id)
                      setOpen(true)
                    }} style={{ backgroundColor: "#00BA77", borderRadius: "500px", padding: "0.5rem 1rem", border: "none", color: "White" }}>End</button>}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination controls */}
      {totalPages > 1 && (
        <div className="paginationContainer">
          <div className="pages">
            <span>{`Page ${currentPage} of ${totalPages} (${locks?.length} items)`}</span>
          </div>
          <div className="pagination">
            <button
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              <img src={start} alt="start" />
            </button>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src={prev} alt="prev" />
            </button>

            {/* Show the first page */}
            <button
              onClick={() => handlePageChange(1)}
              className={currentPage === 1 ? "active" : ""}
            >
              1
            </button>

            {/* Show ellipses if there are more than two pages */}
            {totalPages > 2 && currentPage > 2 && <span>...</span>}

            {/* Show the current page */}
            {currentPage > 1 && currentPage < totalPages && (
              <button
                onClick={() => handlePageChange(currentPage)}
                className="active"
              // style={{ backgroundColor: "green" }} // Added style for the green background
              >
                {currentPage}
              </button>
            )}

            {/* Show the next page */}
            {totalPages > 1 && currentPage < totalPages && (
              <button onClick={() => handlePageChange(currentPage + 1)}>
                {currentPage + 1}
              </button>
            )}

            {/* Show the last page */}
            {totalPages > 1 && currentPage < totalPages - 1 && <span>...</span>}
            {currentPage + 1 !== totalPages && (
              <button
                onClick={() => handlePageChange(totalPages)}
                className={currentPage === totalPages ? "active" : ""}
              >
                {totalPages}
              </button>
            )}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <img src={next} alt="next" />
            </button>
            <button
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              <img src={end} alt="end" />
            </button>
          </div>
        </div>
      )}
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeIcon={closeIcon}
        styles={{ borderRadius: "0.3125rem" }}
      >
        <div style={{ padding: "2.25rem 4rem" }}>
          <p>Do you want to end this saving?</p>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
            <button onClick={() => {
              if (loading === false) {
                closeSaving(id);
                setOpen(false)
              }
            }} style={{ borderRadius: "0.3125rem", backgroundColor: "#FF5348", border: "1px solid #FF5348", color: "white" }}>Proceed</button>
            <button onClick={onCloseModal} style={{ borderRadius: "0.3125rem", backgroundColor: "#F5F5F5", border: "1px solid black" }}>Cancel</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LockupHistory;
