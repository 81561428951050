import React, {  useEffect, useState  } from 'react'
import { motion } from "framer-motion"
import PropTypes from 'prop-types';
import useOnclickOutside from 'react-cool-onclickoutside'
import ReactDOM from 'react-dom'; // Import ReactDOM

import { IoMdClose } from 'react-icons/io';
import { MdKeyboardBackspace } from 'react-icons/md';

const CustomModal = ({children,heading, setModalOpen, modalWidth, backBtn, key, onClose }) => {
        const [screen, setScreen] = useState(0);

        useEffect(() => {
                const handleResize = () => {
                        setScreen(window.innerWidth);
                };

                //initialize
                handleResize();

                window.addEventListener("resize", handleResize);

                return () => {
                        window.removeEventListener("resize", handleResize);
                };
        }, []);

        const ref = useOnclickOutside(() => {
                setModalOpen();
                if (onClose) {
                        onClose();
                }
        });
        

        useEffect(() => {
                const handleKeyDown = (event) => {
                        if (event.key === 'Escape') {
                                setModalOpen(); // Update the state when Escape is pressed
                        }
                };
            
                document.addEventListener('keydown', handleKeyDown); // Add event listener on component mount
            
                return () => {
                  document.removeEventListener('keydown', handleKeyDown); // Remove event listener on component unmount
                };
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []); // Empty dependency array ensures that the effect runs only once on mount and cleanup on unmount
            

  return ReactDOM.createPortal(
        <div className={  "modalOverlay"  }    >
                <motion.div  initial={{ y: "100%" ,opacity:0 }} animate={{ y: 0 ,opacity:1}} exit={{ y: "100%" ,opacity:0}} transition={{ type: "spring", bounce: 0.3, duration: 0.35 }}  className={"modalBox"} style={{width: screen >900  ? modalWidth  : "95%"}} ref={ref} key={key&&key}>
                        {
                                backBtn ?
                                <button className='circle_cancle' style={{marginBottom:"24px"}} onClick={ (e)=>{e.preventDefault(); backBtn()} } ><MdKeyboardBackspace className="svgleft"/></button>
                                :""
                        }
                        {
                                heading ?
                                <nav >
                                        <h5 className='font-bold text-offBlack text-[20px]'>{heading}</h5>
                                        {
                                                !backBtn ?
                                                <button className='circle_cancle' onClick={(e)=>{e.preventDefault(); setModalOpen()}} ><IoMdClose className="w-[20px] h-[20px] min-w-[20px] min-h-[20px]"/></button>
                                                :""
                                        }
                                </nav>
                                :""
                        }
                        <section>
                                {children}
                        </section>
                </motion.div>
        </div>, document.getElementById('modal-root')
  )
}

CustomModal.defaultProps={
        heading:"",
        isOpen: false ,
        modalWidth: "60%" ,
        

}

CustomModal.propTypes={
        heading:PropTypes.string,
        isOpen:PropTypes.bool.isRequired,
        modalWidth: PropTypes.string,
}

export default CustomModal