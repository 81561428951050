import React, { useEffect, useState } from 'react'
import './addbankdetail.css'
import axios from '../../axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from 'react-redux'

const AddBankDetail = () => {
        const [listOfBanks, setListOfBanks] = useState([])
        const [details, setDetails] = useState({
                bank: "",
                number: ""
        })
        const [loading, setLoading] = useState(false)
        const dispatch = useDispatch()

        const showRequestSuccess = (message) => {
                toast.success(message, {
                        position: toast.POSITION.BOTTOM_CENTER,
                });
        };

        const showRequestFailed = (message) => {
                toast.error(message, {
                        position: toast.POSITION.BOTTOM_CENTER,
                        style: {
                                backgroundColor: "black",
                                color: "white"
                        }
                });
        };

        useEffect(() => {
                const getListOfBanks = async () => {
                        const VITE_REACT_APP_PAYSTACK_SECRETE_KEY = "sk_live_59c15f8ea814ea5dbe33a13b7a400ed9587aa033"
                        try {
                                axios({
                                        method: "GET",
                                        url: "https://api.paystack.co/bank?currency=NGN",
                                        headers: {
                                                Authorization: `Bearer ${VITE_REACT_APP_PAYSTACK_SECRETE_KEY
                                                        }`,
                                                "Content-Type": "application/json",
                                        },
                                })
                                        .then((response) => {
                                                console.log(response)
                                                setListOfBanks(response.data.data.map(bank => (bank.name)));
                                        })
                                        .catch((error) => {
                                                console.error(error);
                                        });
                        } catch (error) { }
                }

                getListOfBanks()
        }, [])


        const updateBankDetails = async () => {
                setLoading(true)
            let deviceType = window.innerWidth <= 768 ? "Mobile" : window.innerWidth <= 1024 ? "Tablet" : "Desktop"
                try {
                        let formdata = new FormData();
                        formdata.append("bank_name", details.bank);
                        formdata.append("account_number", details.number);
                      formdata.append("device_type", deviceType);
                        try {
                                axios.put("auth/profile/", formdata, {
                                        headers: {
                                                "Content-Type": "multipart/form-data",
                                        }
                                }
                                ).then(res => {
                                        console.log(res)
                                        setLoading(false)
                                        dispatch({ type: 'refresh' })
                                        showRequestSuccess("Bank details updated successfully")
                                }).catch(err => {
                                        console.log(err)
                                        setLoading(false)
                                        showRequestFailed("Failed to update bank details")
                                })
                        } catch (error) {
                                console.log(error)
                        }
                } catch (error) {


                }
        }

        return (
                // <div className="rounded-card">
                <div className='addBankDetailCard'>
                        <ToastContainer />
                        <div className='addBankDetail'>
                                <div className='bankDetailHeader'>
                                        <h3>Add bank details</h3>
                                        <p>For security and compliance, withdrawals on Hamma must be sent to an account with the same name used during registration.</p>
                                </div>
                                <div className='chooseBank'>
                                        <p>Bank</p>
                                        <div>
                                                <select onChange={(e) => setDetails({ ...details, bank: e.target.value })}>
                                                        {listOfBanks.map(bank => (<option key={bank} value={bank}>{bank}</option>))}
                                                </select>
                                        </div>
                                </div>
                                <div className='chooseAccountNo'>
                                        <p>Account number</p>
                                        <input onChange={(e) => setDetails({ ...details, number: e.target.value })} type='number' />
                                </div>
                                <div onClick={updateBankDetails} className='buttonToSave'>
                                        <p>{loading ? "Saving..." : "Save"}</p>
                                </div>
                        </div>
                </div>
                // </div>
        )
}

export default AddBankDetail
