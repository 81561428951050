/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import "./withdrawotp.css";
import axios from "../../axios";
import { Link, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WithdrawSuccess from "../../assets/images/Group 448.svg"
import WithdrawFailed from "../../assets/images/Group 450.svg"
import { Helmet } from "react-helmet";

const WithdrawOTP = () => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(["", "", "", "", "", "",]);
  const [otpLoading, setOtpLoading] = useState(false)

  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const handleInputChange = (index, value) => {
    setToken((prevToken) => {
      const newToken = [...prevToken];
      newToken[index] = value;

      if (value === "" && index > 0) {
        inputRefs[index - 1].current.focus();
      } else if (value !== "" && inputRefs[index + 1]) {
        inputRefs[index + 1].current.focus();
      }

      return newToken;
    });
  };

  const [searchParams] = useSearchParams();
  const amount = searchParams.get("amt");
  const id = searchParams.get("id");

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "black",
        color: "white",
      },
    });
  };

  const makeWithdrawal = async () => {
    const otpToken = token.join("");
    let deviceType = window.innerWidth <= 768 ? "Mobile" : window.innerWidth <= 1024 ? "Tablet" : "Desktop"

    setLoading(true);
    try {
      axios
        .post("portfolio/wallet/withdraw/", {
          amount,
          stage: "2",
          otp_request_id: id,
          otp_token: otpToken,
          device_type:deviceType
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
          setToken(["", "", "", "", "", "", "", ""]);
          window.location.search = "?successful=yes"
          showRequestSuccess("Successfully sent withdrawal request");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          window.location.search = "?successful=no"
          showRequestFailed("Failed to send withdrawal request");
        });
    } catch (error) {
      console.log(error);
      showRequestFailed("Failed to send withdrawal request");
    }
  };

  const resendToken = async () => {
    setOtpLoading(true)
    try {
      axios
        .get("portfolio/resend-withdraw-otp/")
        .then((res) => {
          console.log(res);
          setOtpLoading(false);
          showRequestSuccess("OTP sent. Check your email.")
        })
        .catch((err) => {

          showRequestFailed("Failed to resend withdrawal otp");
        });
    } catch (error) {
      console.log(error);
      showRequestFailed("Failed to resend withdrawal otp");
    }
  }

  return (
    <div className="rounded-card-container">
      <Helmet>
        <script src="//fw-cdn.com/11595992/4245246.js" chat="true"></script>
        <script>
          {`
            window.fwSettings = {
              widget_id: 153000001831,
            };
            !(function () {
              if ("function" != typeof window.FreshworksWidget) {
                var n = function () {
                  n.q.push(arguments);
                };
                (n.q = []), (window.FreshworksWidget = n);
              }
            })();
          `}
        </script>
        <script
          type="text/javascript"
          src="https://widget.freshworks.com/widgets/153000001831.js"
          async
          defer
        ></script>
      </Helmet>
      <ToastContainer />
      <div className="rounded-card">
        {window.location.search !== "?successful=yes" && window.location.search !== "?successful=no" && <div className="otpCard">
          <div className="otpHeading">
            <h4>Enter OTP</h4>
          </div>
          <div className="otpText">
            <p>One-Time Password (OTP) has been sent to your email or your authentication app.</p>
          </div>
          <div className="otpCards">
            {Object.keys(token).map((key, index) => (
              <div className="card1" key={key}>
                <input
                  ref={inputRefs[index]}
                  maxLength={1}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  type="text"
                  placeholder=""
                  value={token[key]}
                // disabled={token[key] !== ""}
                />
              </div>
            ))}
          </div>
          <div onClick={makeWithdrawal} className="confirmButton">
            <a href="#">{loading ? "Confirming..." : "Confirm"}</a>
          </div>
          <div className="otpCardBottom">
            <p>Didn't recieve pin? </p>
            <div onClick={resendToken} className="resendText" style={{ cursor: "pointer" }}>
              <p>{otpLoading ? "Please wait..." : "Resend"}</p>
            </div>
          </div>
        </div>}

        {window.location.search === "?successful=no" && <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "8.94rem", paddingBottom: "10.38rem" }}>
          <img src={WithdrawFailed} alt="" width={300} height={300} />
          <h1>Transaction failed</h1>
          <p style={{ textAlign: "center" }}>Oops! an error has occurred. </p>
          <Link to="/wallet/withdraw/">
            <button style={{ background: "#00BA77", border: "1px solid #80808080", display: "flex", alignItems: "center", color: "white", gap: "0.62rem", padding: "0.5rem 6rem", borderRadius: "0.3125rem" }}>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.75 9.99994C18.75 9.83418 18.6841 9.67521 18.5669 9.558C18.4497 9.44079 18.2907 9.37494 18.125 9.37494H3.38374L7.31749 5.44244C7.43485 5.32508 7.50078 5.16591 7.50078 4.99994C7.50078 4.83397 7.43485 4.6748 7.31749 4.55744C7.20013 4.44008 7.04096 4.37415 6.87499 4.37415C6.70902 4.37415 6.54985 4.44008 6.43249 4.55744L1.43249 9.55744C1.37428 9.6155 1.32811 9.68447 1.2966 9.7604C1.26509 9.83633 1.24887 9.91773 1.24887 9.99994C1.24887 10.0822 1.26509 10.1636 1.2966 10.2395C1.32811 10.3154 1.37428 10.3844 1.43249 10.4424L6.43249 15.4424C6.4906 15.5006 6.55958 15.5466 6.63551 15.5781C6.71143 15.6095 6.79281 15.6257 6.87499 15.6257C7.04096 15.6257 7.20013 15.5598 7.31749 15.4424C7.43485 15.3251 7.50078 15.1659 7.50078 14.9999C7.50078 14.834 7.43485 14.6748 7.31749 14.5574L3.38374 10.6249H18.125C18.2907 10.6249 18.4497 10.5591 18.5669 10.4419C18.6841 10.3247 18.75 10.1657 18.75 9.99994Z" fill="#F1F1F1" />
              </svg> */}
              <p>Retry</p>
            </button>
          </Link>
        </div>}

        {window.location.search === "?successful=yes" && <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "8.94rem", paddingBottom: "10.38rem" }}>
          <img src={WithdrawSuccess} alt="" width={300} height={300} />
          <h1>Transaction Successful</h1>
          <p style={{ textAlign: "center" }}>We are processing this transaction to your bank account.</p>
          <Link to="/">
            <button style={{ background: "#00BA77", border: "1px solid #80808080", display: "flex", alignItems: "center", color: "white", gap: "0.62rem", padding: "0.5rem 6rem", borderRadius: "0.3125rem" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.75 9.99994C18.75 9.83418 18.6841 9.67521 18.5669 9.558C18.4497 9.44079 18.2907 9.37494 18.125 9.37494H3.38374L7.31749 5.44244C7.43485 5.32508 7.50078 5.16591 7.50078 4.99994C7.50078 4.83397 7.43485 4.6748 7.31749 4.55744C7.20013 4.44008 7.04096 4.37415 6.87499 4.37415C6.70902 4.37415 6.54985 4.44008 6.43249 4.55744L1.43249 9.55744C1.37428 9.6155 1.32811 9.68447 1.2966 9.7604C1.26509 9.83633 1.24887 9.91773 1.24887 9.99994C1.24887 10.0822 1.26509 10.1636 1.2966 10.2395C1.32811 10.3154 1.37428 10.3844 1.43249 10.4424L6.43249 15.4424C6.4906 15.5006 6.55958 15.5466 6.63551 15.5781C6.71143 15.6095 6.79281 15.6257 6.87499 15.6257C7.04096 15.6257 7.20013 15.5598 7.31749 15.4424C7.43485 15.3251 7.50078 15.1659 7.50078 14.9999C7.50078 14.834 7.43485 14.6748 7.31749 14.5574L3.38374 10.6249H18.125C18.2907 10.6249 18.4497 10.5591 18.5669 10.4419C18.6841 10.3247 18.75 10.1657 18.75 9.99994Z" fill="#F1F1F1" />
              </svg>
              <p>Return to Home</p>
            </button>
          </Link>
        </div>}

      </div>
    </div>
  );
};

export default WithdrawOTP;
