import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./popupsidebar.css";
import home from "../../assets/images/menu/home.svg";
import markets from "../../assets/images/menu/markets.svg";
import holdings from "../../assets/images/menu/holdings.svg";
import wallet from "../../assets/images/menu/wallet.svg";
import alert from "../../assets/images/menu/alert.svg";
// import apps from "../../assets/images/menu/apps.svg";
import homeActive from "../../assets/images/menu/home-active.svg";
import marketsActive from "../../assets/images/menu/markets-active.svg";
import holdingsActive from "../../assets/images/menu/holdings-active.svg";
import walletActive from "../../assets/images/menu/wallet-active.svg";
import alertActive from "../../assets/images/menu/alert-active.svg";
import account from "../../assets/images/account.svg"
import accountActive from "../../assets/images/accountActive.svg"
// import appsActive from "../../assets/images/menu/apps-active.svg";
// import logo from "../../assets/images/logo.svg";
// import refer from "../../assets/images/menu/refer-inactive.svg";
// import { useDispatch, useSelector } from "react-redux";

const PopupSidebar = ({ handleOpenMenu }) => {
        const { pathname } = useLocation();
        // const liveDemo = useSelector((state) => state.page.liveDemo);
        // const dispatch = useDispatch();
        console.log("pathname", pathname);
        const sidebarItems = [
                {
                        name: "Home",
                        img: home,
                        activeImage: homeActive,
                        active: pathname === "/",
                        path: "/",
                },
                {
                        name: "Markets",
                        img: markets,
                        activeImage: marketsActive,
                        active: pathname.startsWith("/markets"),
                        path: "/markets",
                },
                {
                        name: "Holdings",
                        img: holdings,
                        activeImage: holdingsActive,
                        active: pathname.startsWith("/holdings"),
                        path: "/holdings",
                },
                {
                        name: "Wallet",
                        img: wallet,
                        activeImage: walletActive,
                        active: pathname.startsWith("/wallet"),
                        path: "/wallet",
                },
                {
                        name: "Alert",
                        img: alert,
                        activeImage: alertActive,
                        active: pathname.startsWith("/alert"),
                        path: "/alert",
                },
                {
                        name: "Account",
                        img: account,
                        activeImage: accountActive,
                        active: pathname.startsWith("/accounts"),
                        path: "/accounts",
                },
                // {
                //   name: "Apps",
                //   img: apps,
                //   activeImage: appsActive,
                //   active: pathname.startsWith("/apps"),
                //   path: "/apps",
                // },
        ];
        // const handleLiveDemo = (status) => {
        //   dispatch({ type: "setLiveDemo", payload: status });
        // };

        return (
                <>
                        <div className="popupsidebar-container">
                                <div className="user-profile-container">
                                        <Link to="/">
                                                <svg
                                                        // className="ml-[1.37rem]"
                                                        width="100%"
                                                        height="100%"
                                                        viewBox="0 0 940 206"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                >
                                                        <path
                                                                d="M865.04 63.1738H877.533V206H865.04V63.1738Z"
                                                                fill="#161616"
                                                        />
                                                        <path
                                                                d="M802.573 6.86662C802.573 3.07429 805.649 0 809.444 0H933.129C936.924 0 940 3.0743 940 6.86663V71.4131C940 75.2054 936.924 78.2797 933.129 78.2797H809.444C805.649 78.2797 802.573 75.2054 802.573 71.4131V6.86662Z"
                                                                fill="#19F256"
                                                        />
                                                        <path
                                                                d="M207.232 167.966H210.403V205.997H207.232V167.966Z"
                                                                fill="#161616"
                                                        />
                                                        <path
                                                                d="M197.707 164.799H220.964V179.251H197.707V164.799Z"
                                                                fill="#19F256"
                                                        />
                                                        <path
                                                                d="M75.0351 8.23995H119.149V205.999H75.0351V128.955H44.114V205.999H0V8.23995H44.114V85.2836H75.0351V8.23995Z"
                                                                fill="#161616"
                                                        />
                                                        <path
                                                                d="M228.858 8.23995L285.752 205.999H239.989L206.594 90.6396L173.2 205.999H127.437L184.744 8.23995H228.858Z"
                                                                fill="#161616"
                                                        />
                                                        <path
                                                                d="M403.99 8.23995H447.692V205.999H403.578V107.119L380.49 159.443H361.113L338.025 107.119V205.999H293.911V8.23995H338.025L371.008 82.3996L403.99 8.23995Z"
                                                                fill="#161616"
                                                        />
                                                        <path
                                                                d="M574.297 8.23995H617.999V205.999H573.885V107.119L550.797 159.443H531.42L508.332 107.119V205.999H464.218V8.23995H508.332L541.315 82.3996L574.297 8.23995Z"
                                                                fill="#161616"
                                                        />
                                                        <path
                                                                d="M727.701 8.23995L784.596 205.999H738.833L705.438 90.6396L672.043 205.999H626.28L683.587 8.23995H727.701Z"
                                                                fill="#161616"
                                                        />
                                                </svg>

                                                {/* <img src="https://res.cloudinary.com/ddlupbcws/image/upload/v1680791936/logo_2_ft9ja_1_fsh9iz.png" alt="" className='w-[5.75rem] ml-[1.37rem] mb-[4.06rem]' /> */}
                                        </Link>
                                </div>
                                <div className="item-list">
                                        {sidebarItems.map((item, index) => (
                                                <div key={index}>
                                                        <Link
                                                                to={item.path}
                                                                onClick={handleOpenMenu}
                                                                className="menu-item"
                                                        >
                                                                <img
                                                                        src={item.active ? item.activeImage : item.img}
                                                                        alt={`${item.name} Logo`}
                                                                />
                                                                <span className={`item-name ${item.active ? "white" : "grey"}`}>
                                                                        {item.name}
                                                                </span>
                                                        </Link>
                                                </div>
                                        ))}
                                        {/* <div>
            <Link
              target="_blank"
              to={`https://refer.gohamma.com/?token=${localStorage.getItem(
                "access_token"
              )}&refresh_token=${localStorage.getItem("refresh_token")}`}
              className="sidebar-menu-item"
            >
              <img src={refer} alt="Refer Icon" />
              <span className="item-name grey">Refer</span>
            </Link>
          </div> */}
                                        <div>
                                                <Link
                                                        target="_blank"
                                                        to={`https://competition.gohamma.com/`}
                                                        className="sidebar-menu-item"
                                                >
                                                        <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.009 2.6003H19.975V0H4.02499V2.6003H2.99074C1.34706 2.6003 0 3.94635 0 5.59104C0 7.23573 1.34605 8.58203 2.99074 8.58203H4.09479C4.54692 11.9705 7.14824 14.7039 10.472 15.3555V18.3361H13.528V15.3545C16.8518 14.7028 19.4528 11.9695 19.905 8.58102H21.009C22.654 8.58102 24 7.23497 24 5.59003C24 3.94508 22.654 2.6003 21.009 2.6003ZM4.02499 7.0529H2.99074C2.60612 7.04785 2.23898 6.89151 1.96879 6.61774C1.69859 6.34397 1.5471 5.9748 1.5471 5.59015C1.5471 5.20551 1.69859 4.83634 1.96879 4.56257C2.23898 4.2888 2.60612 4.13246 2.99074 4.12741H4.02499V7.0529ZM21.009 7.0529H19.975V4.12741H21.009C21.2027 4.12486 21.395 4.16081 21.5747 4.23318C21.7544 4.30555 21.9179 4.41289 22.0558 4.54897C22.1937 4.68505 22.3032 4.84716 22.3779 5.0259C22.4526 5.20463 22.4911 5.39643 22.4911 5.59015C22.4911 5.78388 22.4526 5.97568 22.3779 6.15441C22.3032 6.33315 22.1937 6.49526 22.0558 6.63134C21.9179 6.76742 21.7544 6.87476 21.5747 6.94713C21.395 7.0195 21.2027 7.05545 21.009 7.0529Z" fill="white" fill-opacity="0.4" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0089 2.6003H19.9749V0H11.9998V18.3351H13.5279V15.3545C16.8516 14.7028 19.4527 11.9695 19.9048 8.58102H21.0089C22.6538 8.58102 23.9999 7.23497 23.9999 5.59003C23.9999 3.94508 22.6538 2.6003 21.0089 2.6003ZM21.0089 7.05391H19.9749V4.12741H21.0089C21.2026 4.12486 21.3949 4.16081 21.5746 4.23318C21.7543 4.30555 21.9178 4.41289 22.0557 4.54897C22.1936 4.68505 22.3031 4.84716 22.3778 5.0259C22.4525 5.20463 22.491 5.39643 22.491 5.59015C22.491 5.78388 22.4525 5.97568 22.3778 6.15441C22.3031 6.33315 22.1936 6.49526 22.0557 6.63134C21.9178 6.76742 21.7543 6.87476 21.5746 6.94713C21.3949 7.0195 21.2026 7.05545 21.0089 7.0529V7.05391Z" fill="white" fill-opacity="0.4" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1427 18.3351H6.85707V19.2295H5.40381V25.0896H18.596V19.2295H17.1427V18.3351Z" fill="white" fill-opacity="0.4" />
                                                                <path d="M15.8069 20.4686H8.19287V23.8508H15.8069V20.4686Z" fill="white" fill-opacity="0.4" />
                                                        </svg>
                                                        <span className="item-name grey">Competition</span>
                                                </Link>
                                        </div>
                                </div>
                        </div>
                        {/* <div className="mobileLiveDemoContainer">
        <div
          onClick={() => handleLiveDemo(false)}
          className={`mobileDemoDiv ${!liveDemo && "activeMobileLiveDemo"}`}
        >
          <p>Demo</p>
        </div>
        <div
          onClick={() => handleLiveDemo(true)}
          className={`mobileLiveDiv ${liveDemo && "activeMobileLiveDemo"}`}
        >
          <p>Live</p>
        </div>
      </div> */}
                </>
        );
};

export default PopupSidebar;
