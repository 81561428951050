/* eslint-disable react-hooks/exhaustive-deps */

import axios from "axios";
import { createChart, ColorType } from "lightweight-charts";
import React, { useEffect, useRef, useState } from "react";
import ChartLoading from "../Loading/ChartLoading";

export const ChartComponent = ({ all, symbol, sorting }) => {
  const [legendValue, setLegendValue] = useState(null);
  const [data1, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(true);
  const backgroundColor = "white";
  const lineColor = "#00ba77";
  const textColor = "black";
  const areaTopColor = "rgb(0, 186, 119,0.3)";
  const areaBottomColor = "rgb(0, 186, 119,0.1)";
  const name = all && all.filter((a) => a.symbol === symbol)[0];

  // console.log(sorting);

  useEffect(() => {
    // console.log(sorting)
    if (sorting === "5y") {
      // Function to get the start of the week (Monday)
      const getStartOfWeek = (date) => {
        const d = new Date(date);
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
        return new Date(d.setDate(diff));
      };

      // Current date
      const currentDate = new Date();
      const fiveYearsAgo = currentDate.setFullYear(
        currentDate.getFullYear() - 5
      );

      // Filter data for the last 5 years
      const filteredData = data1.filter(
        (item) => item.time * 1000 >= fiveYearsAgo
      );

      // Group data by week
      const groupedData = filteredData.reduce((acc, item) => {
        const startOfWeek = getStartOfWeek(
          new Date(item.time * 1000)
        ).getTime();

        if (!acc[startOfWeek] || item.time * 1000 > acc[startOfWeek].lastTime) {
          acc[startOfWeek] = {
            time: startOfWeek / 1000,
            change: item.change,
            percentage: item.percentage,
            value: item.value,
            lastTime: item.time * 1000, // keep track of the latest time in the week
          };
        }

        return acc;
      }, {});

      // Convert the result back to an array and remove the `lastTime` property
      const result = Object.values(groupedData).map(
        ({ lastTime, ...rest }) => rest
      );
      setData2(result.sort((a, b) => a.time - b.time));

      console.log(result);
    } else if (sorting === "3y") {
      // Function to get the start of the week (Monday)
      const getStartOfWeek = (date) => {
        const d = new Date(date);
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
        return new Date(d.setDate(diff));
      };

      // Current date
      const currentDate = new Date();
      const fiveYearsAgo = currentDate.setFullYear(
        currentDate.getFullYear() - 3
      );

      // Filter data for the last 5 years
      const filteredData = data1.filter(
        (item) => item.time * 1000 >= fiveYearsAgo
      );

      // Group data by week
      const groupedData = filteredData.reduce((acc, item) => {
        const startOfWeek = getStartOfWeek(
          new Date(item.time * 1000)
        ).getTime();

        if (!acc[startOfWeek] || item.time * 1000 > acc[startOfWeek].lastTime) {
          acc[startOfWeek] = {
            time: startOfWeek / 1000,
            change: item.change,
            percentage: item.percentage,
            value: item.value,
            lastTime: item.time * 1000, // keep track of the latest time in the week
          };
        }

        return acc;
      }, {});

      // Convert the result back to an array and remove the `lastTime` property
      const result = Object.values(groupedData).map(
        ({ lastTime, ...rest }) => rest
      );
      setData2(result.sort((a, b) => a.time - b.time));

      console.log(result);
    } else if (sorting === "1y") {
      // Function to get the start of the week (Monday)
      const getStartOfWeek = (date) => {
        const d = new Date(date);
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
        return new Date(d.setDate(diff));
      };

      // Current date
      const currentDate = new Date();
      const fiveYearsAgo = currentDate.setFullYear(
        currentDate.getFullYear() - 1
      );

      // Filter data for the last 5 years
      const filteredData = data1.filter(
        (item) => item.time * 1000 >= fiveYearsAgo
      );

      // Group data by week
      const groupedData = filteredData.reduce((acc, item) => {
        const startOfWeek = getStartOfWeek(
          new Date(item.time * 1000)
        ).getTime();

        if (!acc[startOfWeek] || item.time * 1000 > acc[startOfWeek].lastTime) {
          acc[startOfWeek] = {
            time: startOfWeek / 1000,
            change: item.change,
            percentage: item.percentage,
            value: item.value,
            lastTime: item.time * 1000, // keep track of the latest time in the week
          };
        }

        return acc;
      }, {});

      // Convert the result back to an array and remove the `lastTime` property
      const result = Object.values(groupedData).map(
        ({ lastTime, ...rest }) => rest
      );
      setData2(result.sort((a, b) => a.time - b.time));

      console.log(result);
    } else if (sorting === "6m") {
      // Get the current timestamp and calculate the timestamp for 6 months ago
      const currentTime = Math.floor(Date.now() / 1000);
      const sixMonthsAgo = currentTime - 6 * 30 * 24 * 60 * 60; // Approximate 6 months in seconds

      // Filter the data to include only entries from the last 6 months
      const recentData = data1.filter((entry) => entry.time >= sixMonthsAgo);

      console.log(recentData);
      setData2(recentData.sort((a, b) => a.time - b.time));
    } else if (sorting === "3m") {
      // Get the current timestamp and calculate the timestamp for 6 months ago
      const currentTime = Math.floor(Date.now() / 1000);
      const sixMonthsAgo = currentTime - 3 * 30 * 24 * 60 * 60; // Approximate 6 months in seconds

      // Filter the data to include only entries from the last 6 months
      const recentData = data1.filter((entry) => entry.time >= sixMonthsAgo);

      console.log(recentData);
      setData2(recentData.sort((a, b) => a.time - b.time));
    } else if (sorting === "1m") {
      // Get the current timestamp and calculate the timestamp for 6 months ago
      const currentTime = Math.floor(Date.now() / 1000);
      const sixMonthsAgo = currentTime - 1 * 30 * 24 * 60 * 60; // Approximate 6 months in seconds

      // Filter the data to include only entries from the last 6 months
      const recentData = data1.filter((entry) => entry.time >= sixMonthsAgo);

      console.log(recentData);
      setData2(recentData.sort((a, b) => a.time - b.time));
    }
  }, [sorting, symbol]);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        axios
          .get(
            `https://tradingview-realtime-11e3ea0312f6.herokuapp.com/simple?symbol=${symbol}`
          )
          .then((res) => {
            console.log(res);
            setData(res.data);
            setLegendValue(res.data[res.data.length - 1]);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } catch (error) {
        setLoading(false);
      }
    };

    fetch();
  }, [symbol]);

  const data = sorting === "" ? data1 : data2;

  const chartContainerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current?.clientWidth });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      width: chartContainerRef.current?.clientWidth,
      // height: window.innerWidth > 700 ? chartContainerRef.current.clientHeight : 400,
      height: window.innerWidth > 1439 ? 500 : 400,
      grid: {
        vertLines: { color: "rgb(128,128,128,0.1)" },
        horzLines: { color: "rgb(128,128,128,0.1)" },
      },
    });
    chart.timeScale().applyOptions({
      // rightOffset: 200,
      // barSpacing:15,
      // minBarSpacing:5,
      fixLeftEdge: true,
      fixRightEdge: true,
      // timeVisible:true
    });

    // chart.timeScale().fitContent();

    const newSeries = chart.addAreaSeries({
      lineColor,
      topColor: areaTopColor,
      bottomColor: areaBottomColor,
      lineWidth: 1.5,
    });
    newSeries.setData(data);

    chart.subscribeCrosshairMove((params) => {
      if (params.time) {
        const data = params.seriesData.get(newSeries);
        console.log(data);
        setLegendValue(data);
      }
    });

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [
    data,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
  ]);

  return (
    <>
      {loading ? <ChartLoading /> : ""}
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          opacity: loading ? "0" : "1",
        }}
        ref={chartContainerRef}
      >
        {legendValue && name && (
          <div
            style={{
              fontSize: `${window.innerWidth < 700 ? "0.58rem" : "1rem"}`,
              position: "absolute",
              top: 20,
              left: `${window.innerWidth < 700 ? 10 : 50}`,
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              gap: `${window.innerWidth < 700 ? "2px" : "5px"}`,
            }}
          >
            <img
              src={name.logo_url}
              alt=""
              style={{ width: "16px", height: "16px" }}
            />
            {name.company + " . NSENG ."}
            <div>CLOSE PRICE: {legendValue && legendValue.value}</div>
          </div>
        )}
      </div>
    </>
  );
};

// export function App(props) {
//     return (
//         <ChartComponent {...props} data={initialData}></ChartComponent>
//     );
// }
