/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./withdraw.css";
import WithdrawImg from "../../assets/images/Group 172.svg";
// import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LiveWithdraw() {
  //   const [bankDetails, setBankDetails] = useState({
  //     accountNumber: "",
  //     bankName: "",
  //   });
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const account = useSelector((state) => state.account);
  const [accountsLive, setAccountsLive] = useState();
  // console.log(account);
  // console.log(accountsLive);
  //   const [isBankDetail, setIsBankDetail] = useState(false);

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "black",
        color: "white",
      },
    });
  };

  useEffect(() => {
    const fetchAccountsLive = async () => {
      axios
        .get("/execution/live/accounts/")
        .then((res) => {
          console.log(res);
          setAccountsLive(
            res.data.accounts.filter((acc) => acc.id === account.id)[0]
          );
          setBalance(
            res.data.accounts.filter((acc) => acc.id === account.id)[0].balance
          );
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchAccountsLive();
  }, [account]);

  //   const handleBankNameChange = (e) => {
  //     setBankDetails((prevBankDetails) => ({
  //       ...prevBankDetails,
  //       bankName: e.target.value,
  //     }));
  //   };

  //   const handleAccountNumberChange = (e) => {
  //     setBankDetails((prevBankDetails) => ({
  //       ...prevBankDetails,
  //       accountNumber: e.target.value,
  //     }));
  //   };

  //   useEffect(() => {
  //     axios
  //       .get("auth/profile/")
  //       .then((res) => {
  //         console.log("response", res);
  //         if (res.data.detail.account_number && res.data.detail.bank_name) {
  //           setIsBankDetail(true);
  //         }
  //         setBankDetails({
  //           accountNumber: res.data.detail.account_number,
  //           bankName: res.data.detail.bank_name,
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     axios
  //       .get("portfolio/wallet")
  //       .then((res) => {
  //         console.log(res);
  //         setBalance(res.data.balance);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }, []);

  const makeWithdrawal = async () => {
    setLoading(true);
    let deviceType = window.innerWidth <= 768 ? "Mobile" : window.innerWidth <= 1024 ? "Tablet" : "Desktop"
    try {
      axios
        .post("portfolio/withdraw-live/", {
          amount,
          account_id: account.id,
          device_type:deviceType
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
          showRequestSuccess("Funds are now in your wallet");
          // navigate(
          //   `/wallet/withdraw/otp?id=${res.data.otp_request_id}&amt=${amount}`
          // );
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showRequestFailed("Failed to transfer funds to your wallet.");
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="rounded-card-container">
      <ToastContainer />
      <Helmet>
        <script src="//fw-cdn.com/11595992/4245246.js" chat="true"></script>
        <script>
          {`
                window.fwSettings = {
                  widget_id: 153000001831,
                };
                !(function () {
                  if ("function" != typeof window.FreshworksWidget) {
                    var n = function () {
                      n.q.push(arguments);
                    };
                    (n.q = []), (window.FreshworksWidget = n);
                  }
                })();
              `}
        </script>
        <script
          type="text/javascript"
          src="https://widget.freshworks.com/widgets/153000001831.js"
          async
          defer
        ></script>
      </Helmet>
      <div className="rounded-card">
        <div className="withdrawContainer">
          <div className="withdrawHeading">
            <h4>Withdraw funds from</h4>
          </div>
          {account.acc_type === "live_portfolio" ? (
            <div className="shadow-lg p-5 mt-5">
              <div className="flex items-center my-5">
                <div className="bg-[#00BA77] h-[32px] w-[32px] flex items-center justify-center rounded-full mr-[8px]">
                  <svg
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.150391 3.68226H1.36251V0.0458984H2.57463L4.64736 3.68226H7.42312V0.0458984H8.63524V3.68226H9.84736V4.89438H8.63524V6.1065H9.84736V7.31863H8.63524V10.955H7.42312L5.34433 7.31863H2.57463V10.955H1.36251V7.31863H0.150391V6.1065H1.36251V4.89438H0.150391V3.68226ZM2.57463 3.68226H3.25948L2.57463 2.48832V3.68226ZM2.57463 4.89438V6.1065H4.64736L3.95645 4.89438H2.57463ZM7.42312 8.53075V7.31863H6.72615L7.42312 8.53075ZM5.33827 4.89438L6.03524 6.1065H7.42312V4.89438H5.33827Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="mr-[21px]">
                  <h1 className="font-medium text-[#151515] whitespace-nowrap">
                    {accountsLive?.account_name}
                  </h1>
                  <p className="text-[#989898] text-[0.75rem]">
                    ID: {accountsLive?.id}{" "}
                  </p>
                </div>
              </div>
              <div>
                <p className="flex items-center whitespace-nowrap gap-[8px] text-[#989898]">
                  Balance:{" "}
                  <span className="font-bold text-[20px] text-[#151515]">
                    {Number(accountsLive?.balance).toLocaleString()} NGN
                  </span>
                </p>
              </div>
            </div>
          ) : (
            <div>
              <p className="text-red-500 my-5">
                You need to select a Live Account.
              </p>
            </div>
          )}
          {/* <div className="portfolioBalance">
            <p>Reserve Balance</p>
            <div className="portfolioAmount">
              <p>N{balance.toLocaleString()}</p>
            </div>
          </div> */}
          {/* <p
            style={{
              color: "#B1B1B1",
              fontSize: "0.75rem",
              textAlign: "center",
            }}
          >
            For security and compliance, withdrawals on Hamma must be sent to an
            account with the same name used during registration.
          </p> */}
          {/* {!isBankDetail && (
            <Link to="/payment">
              <div className="changeDetail">
                <p>+ Add Payment details</p>
              </div>
            </Link>
          )} */}
          {/* <div className="bankNameCard">
            <p>Bank name</p>
            <div className="branchName">
              <input
                type="text"
                value={bankDetails.bankName}
                placeholder="Enter bank name"
                onChange={handleBankNameChange}
                readOnly
                style={{ width: "95%" }}
              />
            </div>
          </div>
          <div className="accountNumberCard">
            <p>Account number</p>
            <div className="accountNumber">
              <input
                type="text"
                value={bankDetails.accountNumber}
                placeholder="Enter account number"
                onChange={handleAccountNumberChange}
                readOnly
                style={{ width: "95%" }}
              />
            </div>
          </div> */}
          {/* <div className="koredeText">
                <p>Adebayo korede</p>
              </div> */}
          <div className="enterAmountCard">
            <p>Enter amount</p>
            <div className="enterAmount">
              <div className="enterAmountLeft">
                <img src={WithdrawImg} alt="enter amount" />
                <p>NGN</p>
              </div>
              <div
                className="enterAmountRight"
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  borderColor: `${amount > balance ? "#B20F19" : "#80808080"}`,
                }}
              >
                <p
                  style={{ color: `${amount > balance ? "#B20F19" : "black"}` }}
                >
                  N
                </p>
                <input
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                  style={{
                    width: "100%",
                    outline: "none",
                    border: "none",
                    color: `${amount > balance ? "#B20F19" : "black"}`,
                  }}
                />
              </div>
            </div>
            {amount > balance && (
              <p
                style={{
                  color: "#B20F19",
                  marginLeft: "auto",
                  fontSize: "0.75rem",
                  marginTop: "0.88rem",
                }}
              >
                You do not have enough funds in your live account to withdraw!
              </p>
            )}
          </div>
          <div onClick={makeWithdrawal} className="withdrawButton">
            <a href="#">
              {loading ? "Sending request..." : "Withdraw to wallet"}
            </a>
          </div>
          {/* {isBankDetail && (
            <Link to="/payment">
              <div className="changeDetail">
                <p>Change Payout details</p>
              </div>
            </Link>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default LiveWithdraw;
