/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import './index.css';
import { widget } from '../../charting_library';
// import Datafeed from "../trading-view/datafeed"

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const TVChartContainer = ({ symbol }) => {
	const chartContainerRef = useRef();
	console.log(symbol)

	const defaultProps = {
		symbol: symbol,
		interval: '1D',
		// datafeedUrl: 'https://demo_feed.tradingview.com',
		datafeedUrl: 'https://tradingview-realtime-11e3ea0312f6.herokuapp.com',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		chartType: "area",
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {
			
		},
	};

	useEffect(() => {
		const widgetOptions = {
			symbol: defaultProps.symbol,
			// BEWARE: no trailing slash is expected in feed URL
			datafeed: new window.Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
			// datafeed: Datafeed,
			interval: defaultProps.interval,
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath,

			locale: getLanguageFromURL() || 'en',
			disabled_features: [''],
			enabled_features: ['study_templates'],
			charts_storage_url: defaultProps.chartsStorageUrl,
			charts_storage_api_version: defaultProps.chartsStorageApiVersion,
			client_id: defaultProps.clientId,
			chartType: "Area",
			user_id: defaultProps.userId,
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
			// timezone: "Africa/Lagos",
			// debug:true,
			// overrides: {
			// 	"mainSeriesProperties.style": "3"
			// },
			// favourites: {
			// 	chartTypes: ["Area"]
			// }
		};

		const tvWidget = new widget(widgetOptions);
		

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
					title: 'Notification',
					body: 'TradingView Charting Library API works correctly',
					callback: () => {
						console.log('Noticed!');
					},
				}));

				button.innerHTML = 'Check API';
			});

			tvWidget.applyOverrides({
				"mainSeriesProperties.style": 1,
				
			})
		});

		return () => {
			tvWidget.remove();
		};
	}, [symbol]);

	return (
		<div
			ref={chartContainerRef}
			className={'TVChartContainer'}
			// style={{ width: "100%", height: `${window.innerWidth < 700 ? "450px" : "400px"}`}}
			style={{ width: "100%", height: `${window.innerWidth > 1439 ? "500px" : "400px"}`}}
		/>
	);
}