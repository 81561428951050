import "./tabscomponent.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export default function TabsComponent({ data }) {
        const path = window.location.pathname?.split('/')[1];

        const dispatch = useDispatch();
        const profileComp = useSelector((state) => state.page.profileComp);
        const handleTabClick = (path) => {
                dispatch({ type: "setProfileComp", payload: path });
                console.log("profileComp", profileComp);
                if (path === "/accountinformation") {
                        dispatch({
                                type: "setAccountInformation",
                                payload: "accountInformation",
                        });
                } else if (path === "/payment") {
                        dispatch({ type: "setAddBank", payload: false });
                } else if (path === "/privacysecurity") {
                        dispatch({ type: "setChangePassword", payload: false });
                }
        };


        useEffect(() => {
                console.log("path",path)
                if(!path) handleTabClick("/profile")
                else handleTabClick("/"+path)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        
        return (
                <div className="">
                        <div className="">
                                <ul className="flex items-center  md:gap-3 md:justify-center overflow-x-scroll  no-scrollbar z-10 border-b border-solid ">
                                        {data.map((tab) => (
                                                <Link
                                                        to={tab.path}
                                                        onClick={() => handleTabClick(tab.path)}
                                                        className={` !px-3 !py-2 ${tab.path === profileComp ? "grid-active" : "grid-nonactive"}`}
                                                        key={tab.path}
                                                >
                                                        <li className={`grid-item whitespace-nowrap text-sm text-[#161616]`}>{tab.name}</li>
                                                </Link>
                                        ))}
                                </ul>
                        </div>
                        {data.map((tab) => (
                                <div
                                        key={tab.name}
                                        className={tab.path === profileComp ? "block" : "hidden"}
                                >
                                        {tab.content}
                                </div>
                        ))}
                </div>
        );
}
