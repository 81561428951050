import React from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import "../../GitGraphs.css";
// import { Grid, Box } from "@mui/material";
// import axios from "../../axios";
import Carousel from "react-material-ui-carousel";
function TradingDays({ cal }) {
    const [info, setInfo] = React.useState([]);
    const calendarStartDate = new Date("2024-05-01");
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [val, setVal] = React.useState("");
    // console.log(cal)

    const calendarDatesArray = [];
    for (let i = 1; i < 13; i++) {
        calendarDatesArray.push({
            start: new Date(
                Date.UTC(
                    calendarStartDate.getFullYear(),
                    calendarStartDate.getMonth() + i - 1,
                    0
                )
            ),
            end: new Date(
                Date.UTC(
                    calendarStartDate.getFullYear(),
                    calendarStartDate.getMonth() + i,
                    0
                )
            ),
        });
    }
    React.useEffect(() => {
        // axios
        //     .get("/leaderboard/trading-days/")
        //     .then((res) => {
        //         console.log(res.data);
        //         setInfo(
        //             res.data.trading_days.map((item) => {
        //                 return { date: item };
        //             })
        //         );
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });

        setInfo(
            cal.map((item) => {
                return { date: item.date, count:item.return };
            })
        );
    }, [cal]);

    let handleClick = (value) => {
        console.log(value)
        if (value === null) {
            setOpen2(true);
            setVal("no trading record for this day");
        } else {
            setOpen(true);
            setVal(value);
            //console.log(value);
        }
    };
    // console.log(calendarDatesArray)

    return (
        <Carousel
            autoPlay={false}
            animation="slide"
            indicators={true}
            navButtonsProps={{
                style: {
                    backgroundColor: "#359602",
                },
            }}
            // height={200}
            navButtonsAlwaysVisible={true}
        >
            {calendarDatesArray.map((date, index) => (
                // <Grid item xs={9} key={index}>

                <div key={index} style={{ margin: "10px 25% 0 25%", width: `${window.innerWidth < 700 ? "150px" : "150px"}`, height:"156px", }}>
                    <dialog
                        onClick={() => {
                            setOpen(false);
                        }}
                        open={open}
                    >
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"20px"}}>
                            Traded on this day {val.date}
                            {val.count > 0 && <p style={{fontSize:"16px", margin:0}}>You made a profit of {val.count}%</p>}
                            {val.count < 0 && <p style={{fontSize:"16px", color:"red", margin:0}}>You had a loss of {val.count}%</p>}
                            {val.count === 0 && <p style={{fontSize:"16px", margin:0}}>You made zero net profit.</p>}
                            <span className="cursor-pointer">Click to close</span>
                        </div>
                    </dialog>
                    <dialog
                        onClick={() => {
                            setOpen2(false);
                        }}
                        open={open2}
                    >
                        <div>
                            No trading record for this day
                            <br />
                            <br />
                            <span className="cursor-pointer">Click to close</span>
                        </div>
                    </dialog>
                  
                    <CalendarHeatmap
                        showWeekdayLabels={true}
                        startDate={date.start}
                        weekdayLabels={["Mon", "Mon", "Wed", "Wed", "Fri", "Fri", "Sun"]}
                        monthLabels={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                        endDate={date.end}
                        onClick={handleClick}
                        // horizontal={false}
                        classForValue={(value) => {
                            if (!value) {
                                return 'color-empty';
                            }else if(value.count > 20){
                                return `color-scale-deep-green`;
                            }else if(value.count > 10 && value.count < 10) {
                                return `color-scale-light-green`;
                            } else if (value.count > 0 && value.count < 10){
                                return `color-scale-lighter-green`;
                            }else if (value.count < -20){
                                return `color-scale-deep-red`;
                            }else if (value.count < -10 && value.count > -20){
                                return `color-scale-light-red`;
                            }else if (value.count < 0 && value.count > -10){
                                return `color-scale-lighter-red`;
                            }else if(value.count === 0){
                                return `color-scale-yellow`; 
                            }
                           
                        }}
                        values={info}
                    />
                </div>
                // </Grid>
            ))}
        </Carousel>
        // </Grid>
    );
}

export default TradingDays;