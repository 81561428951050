import React from 'react'
import "./stockNameLoading.css"

function StockNameLoading() {
  return (
    <div class="stock-name-loading-container">
        <div class="stock-name-loading-animation"></div>
    </div>
)
}

export default StockNameLoading