import React from 'react'
import { useEffect } from 'react'

function Logout() {

    useEffect(() => {
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        window.location.href = "https://competition.gohamma.com/login"
    }, [])
  return (
    <div>Logout</div>
  )
}

export default Logout