const initialState = false;

const refresh = (state = initialState, action) => {
    switch (action.type) {
      case "refresh":
        return !state;
      default:
        return state;
    }
  };

export default refresh