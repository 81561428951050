const initialState = {
  history: false,
  profile: false,
  changePassword: false,
  addBank: false,
  accountInformation: null,
  reload: false,
  profileComp: "",
  expanded: false,
  confirm: false,
  stockSelected: null,
  walletPage: "wallet",
  lockup: false,
  liveDemo: false,
  stockSearch: "",
  openNotif: false,
  openAccounts: false,
  balance: 0,
};

const pageDetail = (state = initialState, action) => {
  switch (action.type) {
    case "setHistory":
      return {
        ...state,
        history: action.payload,
      };
    case "setProfile":
      return {
        ...state,
        profile: action.payload,
      };
    case "setProfileComp":
      return {
        ...state,
        profileComp: action.payload,
      };

    case "setChangePassword":
      return {
        ...state,
        changePassword: action.payload,
      };

    case "setAddBank":
      return {
        ...state,
        addBank: action.payload,
      };

    case "setAccountInformation":
      return {
        ...state,
        accountInformation: action.payload,
      };

    case "setReload":
      return {
        ...state,
        reload: action.payload,
      };

    case "setExpanded":
      return {
        ...state,
        expanded: action.payload,
      };

    case "setConfirm":
      return {
        ...state,
        confirm: action.payload,
      };

    case "setStockSelected":
      return {
        ...state,
        stockSelected: action.payload,
      };

    case "setWalletPage":
      return {
        ...state,
        walletPage: action.payload,
      };

    case "setLockup":
      return {
        ...state,
        lockup: action.payload,
      };

    case "setLiveDemo":
      return {
        ...state,
        liveDemo: action.payload,
      };
    case "searchStock":
      return {
        ...state,
        stockSearch: action.payload,
      };
    case "setNotif":
      return {
        ...state,
        openNotif: action.payload,
      };
    case "setAccount":
      return {
        ...state,
        openAccounts: action.payload,
      };
    case "setBalance":
      return {
        ...state,
        balance: action.payload,
      };
    default:
      return state;
  }
};

export default pageDetail;
