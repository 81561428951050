/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./withdraw.css";
import WithdrawImg from "../../assets/images/Group 172.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../axios";
import { Helmet } from "react-helmet";

const Withdraw = () => {
  const [bankDetails, setBankDetails] = useState({
    accountNumber: "",
    bankName: "",
  });
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [isBankDetail, setIsBankDetail] = useState(false);

  const handleBankNameChange = (e) => {
    setBankDetails((prevBankDetails) => ({
      ...prevBankDetails,
      bankName: e.target.value,
    }));
  };

  const handleAccountNumberChange = (e) => {
    setBankDetails((prevBankDetails) => ({
      ...prevBankDetails,
      accountNumber: e.target.value,
    }));
  };

  useEffect(() => {
    axios
      .get("auth/profile/")
      .then((res) => {
        console.log("response", res);
        if (res.data.detail.account_number && res.data.detail.bank_name) {
          setIsBankDetail(true);
        }
        setBankDetails({
          accountNumber: res.data.detail.account_number,
          bankName: res.data.detail.bank_name,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("portfolio/wallet")
      .then((res) => {
        console.log(res);
        setBalance(res.data.balance);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const makeWithdrawal = async () => {
    setLoading(true);
    let deviceType = window.innerWidth <= 768 ? "Mobile" : window.innerWidth <= 1024 ? "Tablet" : "Desktop"
    try {
      axios
        .post("portfolio/wallet/withdraw/", {
          amount,
          stage: "1",
          device_type:deviceType
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
          navigate(
            `/wallet/withdraw/otp?id=${res.data.otp_request_id}&amt=${amount}`
          );
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="rounded-card-container">
      <Helmet>
        <script src="//fw-cdn.com/11595992/4245246.js" chat="true"></script>
        <script>
          {`
            window.fwSettings = {
              widget_id: 153000001831,
            };
            !(function () {
              if ("function" != typeof window.FreshworksWidget) {
                var n = function () {
                  n.q.push(arguments);
                };
                (n.q = []), (window.FreshworksWidget = n);
              }
            })();
          `}
        </script>
        <script
          type="text/javascript"
          src="https://widget.freshworks.com/widgets/153000001831.js"
          async
          defer
        ></script>
      </Helmet>
      <div className="rounded-card">
        <div className="withdrawContainer">
          <div className="withdrawHeading">
            <h4>Withdraw</h4>
          </div>
          <div className="portfolioBalance">
            <p>Reserve Balance</p>
            <div className="portfolioAmount">
              <p>N{balance.toLocaleString()}</p>
            </div>
          </div>
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "0.75rem",
              textAlign: "center",
            }}
          >
            For security and compliance, withdrawals on Hamma must be sent to an
            account with the same name used during registration.
          </p>
          {!isBankDetail && (
            <Link to="/payment">
              <div className="changeDetail">
                <p>+ Add Payment details</p>
              </div>
            </Link>
          )}
          <div className="bankNameCard">
            <p>Bank name</p>
            <div className="branchName">
              <input
                type="text"
                value={bankDetails.bankName}
                placeholder="Enter bank name"
                onChange={handleBankNameChange}
                readOnly
                style={{ width: "95%" }}
              />
            </div>
          </div>
          <div className="accountNumberCard">
            <p>Account number</p>
            <div className="accountNumber">
              <input
                type="text"
                value={bankDetails.accountNumber}
                placeholder="Enter account number"
                onChange={handleAccountNumberChange}
                readOnly
                style={{ width: "95%" }}
              />
            </div>
          </div>
          {/* <div className="koredeText">
            <p>Adebayo korede</p>
          </div> */}
          <div className="enterAmountCard">
            <p>Enter amount</p>
            <div className="enterAmount">
              <div className="enterAmountLeft">
                <img src={WithdrawImg} alt="enter amount" />
                <p>NGN</p>
              </div>
              <div
                className="enterAmountRight"
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  borderColor: `${amount > balance ? "#B20F19" : "#80808080"}`,
                }}
              >
                <p
                  style={{ color: `${amount > balance ? "#B20F19" : "black"}` }}
                >
                  N
                </p>
                <input
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                  style={{
                    width: "100%",
                    outline: "none",
                    border: "none",
                    color: `${amount > balance ? "#B20F19" : "black"}`,
                  }}
                />
              </div>
            </div>
            {amount > balance && (
              <p
                style={{
                  color: "#B20F19",
                  marginLeft: "auto",
                  fontSize: "0.75rem",
                  marginTop: "0.88rem",
                }}
              >
                You do not have enough funds in your wallet to withdraw!
              </p>
            )}
          </div>
          <div onClick={makeWithdrawal} className="withdrawButton">
            <a href="#">{loading ? "Sending request..." : "Withdraw"}</a>
          </div>
          {isBankDetail && (
            <Link to="/payment">
              <div className="changeDetail">
                <p>Change Payout details</p>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
