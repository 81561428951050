const SvgSearchingData = (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          fill="none"
          viewBox="0 0 88 69"
          {...props}
        >
          <path
            fill="#DBECE9"
            d="M44.736 65.628c17.127 0 31.012-13.887 31.012-31.016 0-17.13-13.885-31.016-31.013-31.016-17.127 0-31.012 13.886-31.012 31.016s13.885 31.016 31.013 31.016Z"
          />
          <path
            fill="#DBECE9"
            d="M85.252 28.567V25.45a2.242 2.242 0 0 0-2.242-2.243h-7.914a2.242 2.242 0 0 1-2.242-2.242v-3.119a2.242 2.242 0 0 1 2.242-2.242h.25a2.241 2.241 0 0 0 2.242-2.242v-3.12A2.244 2.244 0 0 0 75.346 8h-57.5a2.242 2.242 0 0 0-2.241 2.242v3.119a2.243 2.243 0 0 0 2.242 2.242 2.242 2.242 0 0 1 2.242 2.242v3.119a2.242 2.242 0 0 1-2.242 2.242h-8.67a2.242 2.242 0 0 0-2.243 2.243v3.118a2.242 2.242 0 0 0 2.242 2.243h3.402a2.242 2.242 0 0 1 2.243 2.242v3.119a2.243 2.243 0 0 1-2.242 2.242h-.009a2.242 2.242 0 0 0-2.242 2.243v3.118a2.242 2.242 0 0 0 2.242 2.243h.492a2.242 2.242 0 0 1 2.243 2.242v3.119a2.242 2.242 0 0 1-2.242 2.242H4.983a2.242 2.242 0 0 0-2.242 2.242v3.12a2.242 2.242 0 0 0 2.242 2.241H76.55a2.244 2.244 0 0 0 2.242-2.242v-3.119a2.243 2.243 0 0 0-2.242-2.242h-1.944a2.243 2.243 0 0 1-2.242-2.242v-3.119a2.243 2.243 0 0 1 2.242-2.242h4.583a2.242 2.242 0 0 0 2.243-2.243v-3.118a2.243 2.243 0 0 0-2.243-2.243H77.8a2.242 2.242 0 0 1-2.242-2.242v-3.119a2.243 2.243 0 0 1 2.242-2.242h5.211a2.242 2.242 0 0 0 2.242-2.243Z"
          />
          <path
            stroke="#B9DBCD"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={0.591}
            d="M57.43 7.155h5.974M57.43 8.877h5.974M57.43 10.6h5.974M61.566 55.084h5.974M61.566 56.806h5.974M61.566 58.528h5.974M11.988 26.602h5.974M11.988 28.323h5.974M11.988 30.045h5.974"
          />
          <path
            fill="#D8D8D8"
            d="M77.553 49.392 54.406 51.72 31.26 49.39a.395.395 0 0 1-.354-.391l-.546-1.596.546-26.139 23.501 2.365 23.502-2.365.518 26.352L77.907 49a.394.394 0 0 1-.354.392Z"
          />
          <path
            fill="#EBEBEB"
            d="m78.426 47.617-24.02 2.318-24.047-2.53.546-27.49a.395.395 0 0 1 .433-.392l23.068 2.321 23.068-2.32a.394.394 0 0 1 .433.392l.519 27.701Z"
          />
          <path
            fill="#D8D8D8"
            d="m54.219 48.091 23.098-2.247-.964-26.208-20.41 2.054-1.724 26.401Z"
          />
          <path
            fill="#D8D8D8"
            d="M54.219 18.77 74.41 16.5l.935 29.535-21.126 2.056v-29.32Z"
          />
          <path
            fill="#D8D8D8"
            d="m55.349 18.581-21.232-.378 1.135 28.753 18.962 1.135 1.135-29.51Z"
          />
          <path
            fill="#fff"
            d="m54.406 17.21 20.191-2.27 1.42 29.604-21.61 1.987V17.21Z"
          />
          <path
            fill="#C8C8C8"
            d="M54.406 45.55v.98l21.61-1.986-.047-.976-21.563 1.982Z"
          />
          <path
            fill="#fff"
            d="m54.404 17.21-21.232-.379 1.135 28.754 20.096.946V17.21Z"
          />
          <path
            fill="#C8C8C8"
            d="m34.309 45.585 20.096.945v-1.01l-20.096-.945v1.01Z"
          />
          <path
            fill="#C8C8C8"
            d="M35.648 35.992a10.652 10.652 0 0 0 7.976 10.031l8.845.416v-28s-6.683.475-13.613-1.506l-3.708-.066.5 19.125ZM65.684 45.494a16.002 16.002 0 0 0 6.688-13.817l-.81-16.396-4.897.55c-4.476 2.267-9.95 3.688-9.95 3.688v26.8l8.97-.825Z"
          />
          <path
            fill="#fff"
            d="M54.406 17.21s11.302-2.932 14.706-7.094l.952 19.252a16.005 16.005 0 0 1-12.283 16.36l-3.375.803V17.21Z"
          />
          <path
            fill="#C8C8C8"
            d="m57.41 17.21-3.004 29.32s3.826-.289 4.126-.379c3.36-3.082 5.272-8.26 4.816-12.86l-1.831-18.479c-1.216.499-2.408.93-3.458 1.285-.385.67-.648 1.113-.648 1.113Z"
          />
          <path
            fill="#fff"
            d="M54.406 17.21s3.263-5.486 3.641-7.094l2.297 23.175a14.594 14.594 0 0 1-5.938 13.24V17.21ZM54.405 17.21s-9.316.662-17.354-2.837l.533 20.39a10.654 10.654 0 0 0 8.622 10.18l8.199 1.588V17.21Z"
          />
          <path
            fill="#C8C8C8"
            d="M54.406 17.21c.163 2.443.248 4.887.306 7.33.036 1.222.044 2.444.066 3.665l.021 3.665-.02 3.665c-.022 1.222-.03 2.444-.066 3.665a168.956 168.956 0 0 1-.307 7.33c-.164-2.443-.25-4.886-.308-7.33-.037-1.221-.044-2.443-.066-3.665l-.02-3.665.021-3.665c.022-1.221.03-2.443.066-3.665.058-2.443.144-4.887.307-7.33ZM40.645 21.339c.993.084 1.968.204 2.942.318l2.91.349c.97.114 1.938.209 2.91.304.973.093 1.945.15 2.94.235-.97.234-1.962.363-2.96.384a21.607 21.607 0 0 1-2.98-.14 24.84 24.84 0 0 1-2.93-.524 17.49 17.49 0 0 1-2.832-.926ZM40.645 25.547c.993.084 1.968.203 2.942.318l2.91.349c.97.114 1.938.209 2.91.304.973.093 1.945.15 2.94.235-.97.234-1.962.363-2.96.384a21.607 21.607 0 0 1-2.98-.14 24.84 24.84 0 0 1-2.93-.524 17.49 17.49 0 0 1-2.832-.926ZM40.645 29.755c.993.084 1.968.204 2.942.319l2.91.348c.97.114 1.938.21 2.91.305.973.092 1.945.15 2.94.234-.97.235-1.962.364-2.96.385a21.607 21.607 0 0 1-2.98-.141 24.84 24.84 0 0 1-2.93-.524 17.49 17.49 0 0 1-2.832-.926ZM40.645 33.963c.993.084 1.968.204 2.942.319l2.91.348c.97.114 1.938.21 2.91.304.973.093 1.945.15 2.94.235-.97.235-1.962.364-2.96.385a21.607 21.607 0 0 1-2.98-.141 24.84 24.84 0 0 1-2.93-.524 17.49 17.49 0 0 1-2.832-.926ZM40.645 38.171c.993.084 1.968.204 2.942.319l2.91.348c.97.114 1.938.21 2.91.304.973.093 1.945.15 2.94.235-.97.235-1.962.364-2.96.385a21.592 21.592 0 0 1-2.98-.141 24.84 24.84 0 0 1-2.93-.524 17.49 17.49 0 0 1-2.832-.926Z"
          />
          <path
            fill="#EBEBEB"
            d="m68.465 21.902-4.59.774a.394.394 0 0 1-.462-.311l-.152-3.316a.396.396 0 0 1 .312-.462l4.649-.956a.394.394 0 0 1 .462.312l.093 3.497a.394.394 0 0 1-.312.462ZM65.86 30.072l-4.59.774a.392.392 0 0 1-.462-.312l-.152-3.316a.394.394 0 0 1 .311-.462l4.65-.955a.394.394 0 0 1 .461.312l.094 3.497a.394.394 0 0 1-.312.462Z"
          />
          <path
            fill="#898989"
            d="M60.967 26.756a.394.394 0 0 0-.311.462l.152 3.316a.394.394 0 0 0 .462.312l1.805-.305-.41-4.134-1.698.35Z"
          />
          <path
            fill="#EBEBEB"
            d="m67.657 38.242-4.59.774a.394.394 0 0 1-.462-.312l-.152-3.316a.396.396 0 0 1 .311-.462l4.65-.955a.394.394 0 0 1 .461.311l.094 3.498a.394.394 0 0 1-.312.462Z"
          />
          <path
            fill="#898989"
            d="M62.764 34.926a.394.394 0 0 0-.312.462l.153 3.316a.392.392 0 0 0 .196.268c.387-1.36.593-2.765.61-4.179l-.647.133Z"
          />
          <path
            fill="#B9DBCD"
            d="M41.444 62.512a2.554 2.554 0 1 0 0-5.107 2.554 2.554 0 0 0 0 5.107ZM38.043 55.182a1.324 1.324 0 1 0 0-2.648 1.324 1.324 0 0 0 0 2.648ZM46.704 57.073a1.891 1.891 0 1 0 0-3.783 1.891 1.891 0 0 0 0 3.783ZM14.273 48.296a3.038 3.038 0 1 0 0-6.077 3.038 3.038 0 0 0 0 6.077ZM8.47 44.676a1.583 1.583 0 1 0-.001-3.166 1.583 1.583 0 0 0 0 3.166ZM9.92 38.626a.913.913 0 1 0 0-1.826.913.913 0 0 0 0 1.826ZM14.977 40.547a2.188 2.188 0 1 0 0-4.376 2.188 2.188 0 0 0 0 4.376ZM33.398 14.077a3.038 3.038 0 1 0 0-6.076 3.038 3.038 0 0 0 0 6.076ZM33.52 6.426a1.583 1.583 0 1 0 0-3.165 1.583 1.583 0 0 0 0 3.165ZM44.061 7.138a1.37 1.37 0 1 0 0-2.74 1.37 1.37 0 0 0 0 2.74ZM39.077 5.35a.913.913 0 1 0 0-1.826.913.913 0 0 0 0 1.826ZM40.138 11.609a2.188 2.188 0 1 0 0-4.377 2.188 2.188 0 0 0 0 4.377Z"
          />
          <path
            fill="#DBECE9"
            d="m29.955 44.131-3.111-1.775-1.775 3.112 3.111 1.775 1.775-3.112Z"
          />
          <path
            fill="#30A36C"
            d="M41.837 43.33a12.378 12.378 0 1 0-18.118-16.164A12.379 12.379 0 0 0 41.836 43.33Zm-15.409-3.883a9.967 9.967 0 0 1 14.59-13.44 9.967 9.967 0 1 1-14.59 13.44Z"
          />
          <path
            fill="#fff"
            d="M34.38 43.413c5.503 0 9.965-4.462 9.965-9.967 0-5.504-4.462-9.966-9.965-9.966-5.504 0-9.966 4.462-9.966 9.966 0 5.505 4.462 9.967 9.966 9.967Z"
            opacity={0.76}
          />
          <path
            stroke="#DBECE9"
            strokeMiterlimit={10}
            strokeWidth={1.97}
            d="M41.499 33.884a7.13 7.13 0 0 1-10.064 6.053 7.128 7.128 0 0 1-3.31-3.078"
          />
          <path
            fill="#30A36C"
            d="m29.123 47.77-4.984-2.843a.394.394 0 0 0-.537.147l-7.29 12.781a.394.394 0 0 0 .147.538l4.984 2.843c.19.108.43.042.537-.147l7.29-12.782a.394.394 0 0 0-.147-.537Z"
          />
          <path
            fill="#878787"
            d="m21.98 61.089 7.29-12.782a.393.393 0 0 0-.147-.538l-1.24-.706a.394.394 0 0 1 .147.537l-7.29 12.782a.393.393 0 0 1-.537.147l1.24.707a.395.395 0 0 0 .537-.147Z"
          />
          <path
            fill="#40B576"
            d="m17.508 58.539 7.29-12.782a.396.396 0 0 1 .537-.147l1.377.785-2.575-1.469a.394.394 0 0 0-.538.147l-7.29 12.782a.394.394 0 0 0 .148.538l1.198.683a.395.395 0 0 1-.147-.537ZM19.035 59.862l2.408 1.374-2.408-1.374Z"
          />
          <path
            fill="#DBECE9"
            d="m16.436 57.167 6.076 3.466a.394.394 0 0 1 .147.538l-.58 1.017a2.596 2.596 0 0 1-3.54.969l-2.252-1.284a2.594 2.594 0 0 1-.968-3.541l.58-1.017a.394.394 0 0 1 .537-.148Z"
          />
          <path
            fill="#E8EEEF"
            d="m16.496 59.013.58-1.017a.394.394 0 0 1 .537-.148l4.96 2.83a.39.39 0 0 0-.062-.044l-6.076-3.467a.394.394 0 0 0-.537.148l-.58 1.017a2.597 2.597 0 0 0 .968 3.54l1.05.6a2.594 2.594 0 0 1-.84-3.46Z"
          />
          <path
            fill="#BADBD1"
            d="m22.076 62.188.58-1.017a.394.394 0 0 0-.148-.538l-1.244-.71a.394.394 0 0 1 .146.537l-.58 1.017a2.596 2.596 0 0 1-3.541.97l-1.008-.576.002.002 2.252 1.284a2.595 2.595 0 0 0 3.54-.969Z"
          />
        </svg>
      );
      export default SvgSearchingData;