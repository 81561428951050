import { configureStore } from "@reduxjs/toolkit";
import pageDetail from "./reducers/pageDetail";
import refresh from "./reducers/refresh";
import account from "./reducers/account";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  page: pageDetail,
  refresh: refresh,
  account: account,
});

const persistedReducer = persistReducer(persistConfig, reducer)

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
});

export default store;
