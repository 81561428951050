/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./savinglock.css";
import SavingLockImg from "../../assets/images/security-safe.svg";
import { Link } from "react-router-dom";
import axios from "../../axios";
import { Helmet } from "react-helmet";

const SavingLock = () => {
  const [active, setActive] = useState("ongoing");
  const [locks, setLocks] = useState([])
  const [ongoingLocks, setOngoingLocks] = useState([])

  // const locks = [
  //   {
  //     title: "Yearly Save",
  //     amount: "N 600,000",
  //     duration: "60 days",
  //     interestRate: "10%",
  //   },
  //   {
  //     title: "Monthly Save",
  //     amount: "N 600,000",
  //     duration: "30 days",
  //     interestRate: "5%",
  //   },
  // ];

  useEffect(() => {
    axios.get("lock/savings").then((res) => {
      console.log(res);
      setLocks(res.data)
      setOngoingLocks(res.data.filter(lock => lock.status === "ongoings"))

    }).catch(err => {
      console.log(err)
    })
  }, []);

  return (
    <div className="rounded-card-container">
      <Helmet>
        <script src="//fw-cdn.com/11595992/4245246.js" chat="true"></script>
        <script>
          {`
            window.fwSettings = {
              widget_id: 153000001831,
            };
            !(function () {
              if ("function" != typeof window.FreshworksWidget) {
                var n = function () {
                  n.q.push(arguments);
                };
                (n.q = []), (window.FreshworksWidget = n);
              }
            })();
          `}
        </script>
        <script
          type="text/javascript"
          src="https://widget.freshworks.com/widgets/153000001831.js"
          async
          defer
        ></script>
      </Helmet>
      <div className="rounded-card">
        <div className="savingLockCard">
          <div className="savingLockTop">
            <div className="savingLockHeading">
              <img src={SavingLockImg} alt="saving lock" />
              <h4>Saving lock</h4>
            </div>
            <div className="createButton">
              <Link to="/wallet/savinglock/durationselection">
                <a href="#">+Create</a>
              </Link>
            </div>
          </div>
          <div className="savingLockBottom">
            <div className="savingLockLeft">
              <div className="lockWalletCard">
                <div className="lockWalletTop">
                  <p>Lock wallet</p>
                  <div className="contentdiv">
                    <p>+5.0% in the last 30 days</p>
                  </div>
                </div>
                <div className="noText">
                  <p>N</p>
                  <p>{ongoingLocks.length > 0 ? ongoingLocks.reduce((a, b) => {
                    return Number(a.amount) + Number(b.amount)
                  }).toLocaleString() : 0}</p>
                </div>
              </div>
              <div className="interestRateText">
                <p>Interest text</p>
                <h4>5% - 15% p/a</h4>
              </div>
            </div>
            <div className="savingLockRight">
              <div className="savingHeader">
                <p>History</p>
                <div className="savingOptions">
                  <div
                    onClick={() => setActive("ongoing")}
                    className={`${active === "ongoing"
                      ? "active-middle-item"
                      : "nonactive-middle-item"
                      }`}
                  >
                    <p>Ongoing</p>
                  </div>
                  <div
                    onClick={() => setActive("completed")}
                    className={`${active === "completed"
                      ? "active-middle-item"
                      : "nonactive-middle-item"
                      }`}
                  >
                    <p>Completed</p>
                  </div>
                </div>
              </div>
              <table className="lock-table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Amount</th>
                    <th>Duration</th>
                    <th>Interest rate %</th>
                  </tr>
                </thead>
                <tbody>
                  {locks.filter(lock => lock.status === active).map((position, index) => (
                    <tr key={index}>
                      <td>{position.title}</td>
                      <td>{position.amount}</td>
                      <td>{position.duration}</td>
                      <td>{position.interest_rate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavingLock;
