import React, { useState } from 'react'
import "./auth.css"
import axios from '../../axios'

function Authe() {
  const [login, setLogin] = useState({
    email: "",
    password: ""
  })
  const [register, setRegister] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    ref_code: ""
  })

  const [registerToken, setRegisterToken] = useState({
    first: "",
    second: "",
    third: "",
    forth: "",
    fifth: "",
    sixth: "",
    // seventh: "",
    // eight: "",
  })

  const [loginToken, setLoginToken] = useState({
    first: "",
    second: "",
    third: "",
    forth: "",
    fifth: "",
    sixth: "",
    // seventh: "",
    // eight: "",
  })

  console.log(registerToken)

  const Register = async (e) => {
    e.preventDefault()
    localStorage.setItem("user_email", register.email)
    axios.post("auth/register/", register).then(res => {
      console.log(res)
      window.location.search = "?login=yes&register_token=yes"
    }).catch(err => {
      console.log(err)
      alert("Registeration failed, ensure all details are provided and correct.")
    })
  }

  const RegisterVerify = async () => {
    axios.post("auth/verify_token/", {
      user_email: localStorage.getItem("user_email"),
      token: registerToken.first + registerToken.second + registerToken.third + registerToken.forth + registerToken.fifth + registerToken.sixth 
    }).then(res => {
      console.log(res)
      window.location.href = "/auth?login=yes"
    }).catch(err => {
      console.log(err)
      alert("Token verification failed, check token and try again")
    })
  }

  const Login = async (e) => {
    e.preventDefault()
    localStorage.setItem("user_email", login.email)
    axios.post("auth/login/", login).then(res => {
      console.log(res)
      window.location.search = "?login=yes&login_token=yes"
    }).catch(err => {
      console.log(err)
      alert("Login failed, ensure all details are correct.")
    })
  }

  const LoginVerify = async () => {
    axios.post("auth/login-with-2fa/", {
      email: localStorage.getItem("user_email"),
      token: loginToken.first + loginToken.second + loginToken.third + loginToken.forth + loginToken.fifth + loginToken.sixth
    }).then(res => {
      console.log(res)
      localStorage.setItem("access_token", res.data.access)
      localStorage.setItem("refresh_token", res.data.refresh)
      window.location.href = "/"
    }).catch(err => {
      console.log(err)
      alert("Token verification failed, check token and try again")
    })
  }

  return (
    <div className='auth_main_container'>
      {window.location.search === "" &&
        <div className='auth_container'>
          <h1>Register</h1>
          <form className='auth_form' action="">
            <div className='auth_form_div'>
              <label htmlFor="">Email</label>
              <input onChange={(e) => setRegister({ ...register, email: e.target.value })} type="text" />
            </div>
            <div className='auth_form_div'>
              <label htmlFor="">First Name</label>
              <input onChange={(e) => setRegister({ ...register, firstname: e.target.value })} type="text" />
            </div>
            <div className='auth_form_div'>
              <label htmlFor="">Last Name</label>
              <input onChange={(e) => setRegister({ ...register, lastname: e.target.value })} type="text" />
            </div>
            <div className='auth_form_div'>
              <label htmlFor="">Password</label>
              <input onChange={(e) => setRegister({ ...register, password: e.target.value })} type="password" />
            </div>
            <div className='auth_form_div'>
              <label htmlFor="">Referral Code(optional)</label>
              <input onChange={(e) => setRegister({ ...register, ref_code: e.target.value })} type="text" />
            </div>
            <p>Do not have an account? <a href='/auth?login=yes'>Login</a></p>
            <button onClick={Register}>Register</button>
          </form>
        </div>}

      {window.location.search === "?login=yes" &&
        <div className='auth_container'>
          <h1>Login</h1>
          <form className='auth_form' action="">
            <div className='auth_form_div'>
              <label htmlFor="">Email</label>
              <input onChange={(e) => setLogin({ ...login, email: e.target.value })} type="text" />
            </div>
            <div className='auth_form_div'>
              <label htmlFor="">Password</label>
              <input onChange={(e) => setLogin({ ...login, password: e.target.value })} type="password" />
            </div>
            <p>Already have an account? <a href='/auth'>Register</a></p>
            <button onClick={Login}>Login</button>
          </form>
        </div>}

      {window.location.search === "?login=yes&register_token=yes" && <div className='register_token_main_container'>
        <h1>Input token that was sent to your mail or from your auth app.</h1>
        <div className='register_token_container'>
          <input onChange={(e) => setRegisterToken({ ...registerToken, first: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          <input onChange={(e) => setRegisterToken({ ...registerToken, second: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          <input onChange={(e) => setRegisterToken({ ...registerToken, third: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          <input onChange={(e) => setRegisterToken({ ...registerToken, forth: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          <input onChange={(e) => setRegisterToken({ ...registerToken, fifth: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          <input onChange={(e) => setRegisterToken({ ...registerToken, sixth: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          {/* <input onChange={(e) => setRegisterToken({ ...registerToken, seventh: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          <input onChange={(e) => setRegisterToken({ ...registerToken, eight: e.target.value })} maxLength={1} type="text" className='register_token_input' /> */}
        </div>
        <button onClick={RegisterVerify}>Send</button>
      </div>}

      {window.location.search === "?login=yes&login_token=yes" && <div className='register_token_main_container'>
        <h1>Input token that was sent to your mail, or from your auth app.</h1>
        <div className='register_token_container'>
          <input onChange={(e) => setLoginToken({ ...loginToken, first: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          <input onChange={(e) => setLoginToken({ ...loginToken, second: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          <input onChange={(e) => setLoginToken({ ...loginToken, third: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          <input onChange={(e) => setLoginToken({ ...loginToken, forth: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          <input onChange={(e) => setLoginToken({ ...loginToken, fifth: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          <input onChange={(e) => setLoginToken({ ...loginToken, sixth: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          {/* <input onChange={(e) => setLoginToken({ ...loginToken, seventh: e.target.value })} maxLength={1} type="text" className='register_token_input' />
          <input onChange={(e) => setLoginToken({ ...loginToken, eight: e.target.value })} maxLength={1} type="text" className='register_token_input' /> */}
        </div>
        <button onClick={LoginVerify}>Send</button>
      </div>}
    </div>
  )
}

export default Authe