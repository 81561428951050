/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./myaccount.css";
import ArrowDownImg from "../../assets/images/arrow-down.svg";
import axios from "../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelector } from 'react-redux'
import profilePicture from "../../assets/images/default_image.jpeg";

const MyAccount = () => {
        const mainRefresh = useSelector(state => state.refresh)
        const [profile, setProfile] = useState({
                firstName: "",
                lastName: "",
                email: "",
                address: "",
                number: "",
                bankName: "",
                accountNumber: "",
                profile_img: null
        })
        const [loading, setLoading] = useState(false)
        const [refresh, setRefresh] = useState(true)
        const [imageBase64, setImageBase64] = useState('');
        const [file, setFile] = useState(null)
        const [listOfBanks, setListOfBanks] = useState([])
        console.log(profile)


        const showRequestSuccess = (message) => {
                toast.success(message, {
                        position: toast.POSITION.BOTTOM_CENTER,
                });
        };


        const showRequestFailed = (message) => {
                toast.error(message, {
                        position: toast.POSITION.BOTTOM_CENTER,
                        style: {
                                backgroundColor: "black",
                                color: "white"
                        }
                });
        };


        useEffect(() => {
                axios.get("auth/profile/").then(res => {
                        console.log(res)
                        setProfile({ accountNumber: res.data.detail.account_number, profile_img: res.data.detail.profile_img, address: res.data.detail.address, bankName: res.data.detail.bank_name, number: res.data.detail.phone, firstName: res.data.user.firstname, lastName: res.data.user.lastname, email: res.data.user.email })
                }).catch(err => {
                        console.log(err)
                });

                const getListOfBanks = async () => {
                        const VITE_REACT_APP_PAYSTACK_SECRETE_KEY = "sk_live_59c15f8ea814ea5dbe33a13b7a400ed9587aa033"
                        try {
                                axios({
                                        method: "GET",
                                        url: "https://api.paystack.co/bank?currency=NGN",
                                        headers: {
                                                Authorization: `Bearer ${VITE_REACT_APP_PAYSTACK_SECRETE_KEY
                                                        }`,
                                                "Content-Type": "application/json",
                                        },
                                })
                                        .then((response) => {
                                                console.log(response)
                                                setListOfBanks(response.data.data.map(bank => (bank.name)));
                                        })
                                        .catch((error) => {
                                                console.error(error);
                                        });
                        } catch (error) { }
                }

                getListOfBanks()
        }, [refresh, mainRefresh])

        const updateProfile = async () => {
                setLoading(true)
              let deviceType = window.innerWidth <= 768 ? "Mobile" : window.innerWidth <= 1024 ? "Tablet" : "Desktop"
                let formdata = new FormData();
                if (file) {
                        formdata.append("profile_img", file);
                }
                formdata.append("firstname", profile.firstName);
                formdata.append("lastname", profile.lastName);
                formdata.append("email", profile.email);
                formdata.append("address", profile.address);
                formdata.append("phone", profile.number);
                formdata.append("bank_name", profile.bankName);
                formdata.append("account_number", profile.accountNumber);
            formdata.append("device_type", deviceType);
                try {
                        axios.put("auth/profile/", formdata, {
                                headers: {
                                        "Content-Type": "multipart/form-data",
                                }
                        }
                        ).then(res => {
                                console.log(res)
                                setLoading(false)
                                setRefresh(!refresh)
                                setImageBase64("")
                                showRequestSuccess("Your Profile has been updated successfully")
                        }).catch(err => {
                                console.log(err)
                                setLoading(false)
                                showRequestFailed("Failed to update profile")
                        })
                } catch (error) {
                        console.log(error)
                }
        }


        function handleFileUpload(event) {
                const file = event.target.files[0];
                if (file) {
                        const reader = new FileReader();

                        reader.onload = (e) => {
                                const base64String = e.target.result;
                                setImageBase64(base64String);
                        };

                        reader.readAsDataURL(file);
                        setFile(file);
                }
        };


        return (
                <div>
                        <ToastContainer />
                        <div className="accountCard  no-scrollbar">
                                <div className="accountCardLeft">
                                        <div className="circleDiv">
                                                {profile.profile_img === null && imageBase64 === "" && <img className="circle" src={profilePicture} alt="default avater"></img>}
                                                {imageBase64 !== "" ? <img src={imageBase64} alt="" className="circleImg" /> : profile.profile_img !== null && <img src={profile.profile_img} alt="" className="circleImg" />}

                                                <div className="circleText">
                                                        <label htmlFor="profilePic">Edit</label>
                                                        <input onChange={(e) => handleFileUpload(e)} id="profilePic" type="file" value="" style={{ display: "none" }} />
                                                </div>
                                        </div>
                                </div>
                                <div className="accountCardRight">
                                        <div className="accountCardHeader">
                                                <div className="firstName">
                                                        <p>First Name</p>
                                                        <input type="text" value={profile.firstName} placeholder="" onChange={(e) => setProfile({ ...profile, firstName: e.target.value })} />
                                                </div>
                                                <div className="lastName">
                                                        <p>Last Name</p>
                                                        <input type="text" value={profile.lastName} placeholder="" onChange={(e) => setProfile({ ...profile, lastName: e.target.value })} />
                                                </div>
                                        </div>
                                        <div className="accountCardMid">
                                                <div className="emailDiv">
                                                        <p>Email</p>
                                                        <input disabled type="text" value={profile.email} placeholder="" onChange={(e) => setProfile({ ...profile, email: e.target.value })} />
                                                </div>
                                                <div className="phoneNo">
                                                        <p>Phone no</p>
                                                        <input type="text" value={profile.number} placeholder="" onChange={(e) => setProfile({ ...profile, number: e.target.value })} />
                                                </div>
                                        </div>
                                        <div className="accountCardBottom">
                                                <div className="address">
                                                        <p>Address</p>
                                                        <textarea
                                                                className="textarea max-h-[250px]"
                                                                type="text"
                                                                rows={2}
                                                                multiple={true}
                                                                placeholder=""
                                                                value={profile.address}
                                                                onChange={(e) => setProfile({ ...profile, address: e.target.value })}
                                                        />
                                                </div>
                                        </div>

                                        <div disabled={loading} className="buttonSave " onClick={updateProfile}>
                                                <p>{loading ? "Updating..." : "Save"}</p>
                                        </div>
                                </div>
                        </div>
                        {/* <div className="bankInformationCard">
                                <div className="bankInformationHeading ">
                                        <h3>Bank Information</h3>
                                </div>
                                <p style={{ color: "#B1B1B1", fontSize: "0.75rem", textAlign: "center", marginBottom:"16px" }}>For security and compliance, withdrawals on Hamma must be sent to an account with the same name used during registration.</p>
                                <div className="bankInformationMid">
                                        <div className="bank-name">
                                                <p>NGN Bank Name</p>
                                                <div className="selectDiv">
                                                        <select onChange={(e) => setProfile({ ...profile, bankName: e.target.value })}>
                                                                <option selected disabled>{profile.bankName??""}</option>
                                                                {listOfBanks.map(bank => (<option key={bank} value={bank}>{bank}</option>))}
                                                        </select>
                                                </div>
                                        </div>
                                        <div className="account-no">
                                                <p>Account Number</p>
                                                <input type="text" value={profile.accountNumber} placeholder="" onChange={(e) => setProfile({ ...profile, accountNumber: e.target.value })} />
                                        </div>
                                </div>
                                <div disabled={loading} className="buttonSave" onClick={updateProfile}>
                                        <p>{loading ? "Updating..." : "Save"}</p>
                                </div>
                        </div> */}
                </div>
        );

};

export default MyAccount;
