import React, {  useEffect, useRef, useState } from "react";
import Dygraph from "dygraphs";
import "./Graph.css";

const combineArrays = (arr1, arr2, arr3) => {
        // Check if all arrays have the same length
        if (arr1.length !== arr2.length || arr1.length !== arr3.length) {
                console.log('Arrays must have the same length');
        }
      
        let combinedData = '';
        
        // Combine the arrays and format the data
        for (let i = 0; i < arr1.length; i++) {
          combinedData += `${arr1[i]},${arr2[i]},${arr3[i]}\n`;
        }
      
        // Remove the last newline character
        combinedData = combinedData.trimRight();
      
        return combinedData;
}


export default function DyGraph(props) {
        const graphEl = useRef(null);
        const [screen, setScreen] = useState(0);

        useEffect(() => {
                const handleResize = () => {
                        setScreen(window.innerWidth);
                };

                //initialize
                handleResize();

                window.addEventListener("resize", handleResize);

                return () => {
                        window.removeEventListener("resize", handleResize);
                };
        }, []);

        var graphStyle = {
                width: "100%",
                height: screen < 675 ? 200 : 300
        };


        var header = {
                margin: 0,
                opacity: 0.8,
                marginBottom: "1rem"
        };

        // var cleanPresets = {
        //         axes: {
        //                 x: {
        //                         drawGrid: false,
        //                         drawAxis: false
        //                 },
        //                 y: {
        //                         drawGrid: false,
        //                         drawAxis: false
        //                 }
        //         },
        //         rollPeriod: 7,
        //         labelsDiv: "",
        //         highlightCircleSize: 2,
        //         strokeWidth: 2,
        //         legend: "none",
        //         animatedZooms: false,
        //         colors: ['#4817D4',"#008000"]
        // };

        var fullPresets = {
                axes: {
                        x: {
                                drawGrid: false,
                                drawAxis: true,
                                axisLineColor: "white",
                                axisLineWidth: 1.5
                        },
                        y: {
                                drawAxis: true,
                                gridLineWidth: 1.5,
                                gridLineColor: "#eee",
                                gridLinePattern: [5, 5],
                                axisLineColor: "white",
                                axisLineWidth: 1,
                                valueFormatter: (val) => val.toFixed(1) + '%', // Format values with percentage
                                axisLabelFormatter: (val) => val.toFixed(1) + '%',
                        }
                },
                rollPeriod: 10,
                highlightCircleSize: 5,
                labels: ["X", ...props.legendsNames],
                legendFormatter: legendFormatter,
                legend: "follow",
                strokeWidth: 2,
                fillGraph: false,
                colors: props.legendsColors,
                visibility: [true, true],
                animatedZooms: true,
                hideOverlayOnMouseOut: false
        };

        function legendFormatter(data) {
                if (data.x == null) {
                        // This happens when there's no selection and {legend: 'always'} is set.
                        return +data.series
                                .map(function (series) {
                                        return series.dashHTML + " " + series.labelHTML;
                                })
                                .join();
                }

                var html = "<b>" + data.xHTML + "</b>";

                data.series.forEach((series) => {
                        if (!series.isVisible) return;

                        var labeledData = series.labelHTML + " <b>" + series.yHTML + "</b>";

                        if (series.isHighlighted) {
                                labeledData = "<b>" + labeledData + "</b>";
                        }

                        html +=
                                "<div class='dygraph-legend-row'>" +
                                series.dashHTML +
                                "<div>" +
                                labeledData +
                                "</div></div>";
                });
                return html;
        }

        console.log("props data",combineArrays(props.labels,props.arr1, props.arr2), props.arr2)
        useEffect(() => {
                Dygraph.defaultInteractionModel.touchend = Dygraph.defaultInteractionModel.touchmove = Dygraph.defaultInteractionModel.touchstart = function () { };
                new Dygraph(graphEl.current, combineArrays(props.labels,props.arr1, props.arr2), fullPresets);
        });

        return (
                <div >
                        <div style={header}>

                        </div>

                        <div style={graphStyle} className={props.id} ref={graphEl} />
                </div>
        );
}
