/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/delete.svg";
import "./alerthistory.css";
import { Positions } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axios";
import { Popover } from "react-tiny-popover";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";


const ConvertDate = (timestamp) => {
  const date = new Date(timestamp);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return formattedDate;
};




const AlertHistory = ({ setTitle }) => {
  const [isLeftTopScrolling, setIsLeftTopScrolling] = useState(false);
  const [isLeftBottomScrolling, setIsLeftBottomScrolling] = useState(false);
  const alertLeftTopRef = useRef(null);
  const alertLeftBottomRef = useRef(null);
  const dispatch = useDispatch();
  const [alerts, setAlert] = useState([]);
  const refresh = useSelector((state) => state.refresh);
  const [popoverID, setPopoverId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null)

  useEffect(() => {
    axios
      .get("portfolio/alert/")
      .then((res) => {
        console.log(res);
        setAlert(res.data.alerts);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setIsLeftTopScrolling(true)
  //   }

  //   const hideScrollbar = () => {
  //     setIsLeftTopScrolling(false)
  //   }

  //   const alertLeftTopElement = alertLeftTopRef.current

  //   alertLeftTopElement.addEventListener('scroll', handleScroll)
  //   alertLeftTopElement.addEventListener('mouseleave', hideScrollbar)

  //   return () => {
  //     alertLeftTopElement.removeEventListener('scroll', handleScroll)
  //     alertLeftTopElement.removeEventListener('mouseleave', hideScrollbar)
  //   }
  // }, [])

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setIsLeftBottomScrolling(true)
  //   }

  //   const hideScrollbar = () => {
  //     setIsLeftBottomScrolling(false)
  //   }

  //   const alertLeftBottomElement = alertLeftBottomRef.current

  //   alertLeftBottomElement.addEventListener('scroll', handleScroll)
  //   alertLeftBottomElement.addEventListener('mouseleave', hideScrollbar)

  //   return () => {
  //     alertLeftBottomElement.removeEventListener('scroll', handleScroll)
  //     alertLeftBottomElement.removeEventListener('mouseleave', hideScrollbar)
  //   }
  // }, [])

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "black",
        color: "white",
      },
    });
  };

  const { pathname } = useLocation();

  const deleteAlert = async (id) => {
    setLoading(true);
    try {
      axios
        .delete(`portfolio/alert/${id}/`)
        .then((res) => {
          console.log(res);
          dispatch({ type: "refresh" });
          setLoading(false);
          showRequestSuccess("Successfully deleted alert");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          if (
            err.response.data.detail ===
            "You have reached the maximum limit of 10 alerts for this month."
          ) {
            showRequestFailed(
              "You have reached the maximum limit of 10 alerts for this month."
            );
          } else {
            showRequestFailed("Failed to delete alert");
          }
        });
    } catch (error) { }
  };

  const onCloseModal = () => setOpen(false);
  const closeIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path d="M9.85397 7.50067L13.8545 11.5012C14.1143 11.761 14.1143 12.183 13.8545 12.4429L12.4429 13.8545C12.183 14.1144 11.7617 14.1144 11.5012 13.8545L7.5 9.85398L3.49884 13.8545C3.23897 14.1144 2.81765 14.1144 2.55778 13.8545L1.14553 12.4429C0.885654 12.183 0.885654 11.7617 1.14553 11.5012L5.14668 7.50067L1.14553 3.49951C0.885654 3.23964 0.885654 2.81767 1.14553 2.55779L2.55844 1.1462C2.81831 0.886325 3.23962 0.886325 3.4995 1.1462L7.5 5.14736L11.5012 1.1462C11.761 0.886325 12.1823 0.886325 12.4429 1.1462L13.8545 2.55845C14.1143 2.81833 14.1143 3.23964 13.8545 3.50017L9.85397 7.50067Z" fill="#151515" />
    </svg>
  );
  return (
    <>
      <div className="historyMobileCard" >
        <Link onClick={() => dispatch({ type: "setHistory", payload: false })} to="/alert"><div className="newChatButton" >
          <p>New Alert</p>
        </div></Link>
      </div>
      <div className="manualHistory">
        <div
          className={`alertLeftBottom ${isLeftBottomScrolling ? "scrolling" : "notscrolling"
            }`}
          ref={alertLeftBottomRef}
        >
          <h4>Alert</h4>
          <table className="custom-table">
            <thead>
              <tr>
                <th>Symbol</th>
                <th>Time</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {alerts.map((alert, index) => (
                <tr key={index}>
                  <td>{alert.stock}</td>
                  <td>{ConvertDate(alert.expire)}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1.25rem",
                      }}
                    >
                      <Link to={`/alert?id=${alert.id}`} onClick={() => dispatch({ type: "setHistory", payload: false })}>
                        <img src={editIcon} alt="Alert" />
                      </Link>
                      <img
                        onClick={() => {
                          setId(alert.id)
                          setOpen(true)
                        }}
                        src={deleteIcon}
                        alt="Alert"
                        style={{ cursor: "pointer" }}
                      />
                      <Popover
                        isOpen={alert.id === popoverID && true}
                        positions={["right", "bottom", "left", "top"]} // preferred positions by priority
                        content={
                          <div
                            style={{
                              background: "white",
                              padding: "0.75rem",
                              borderRadius: "0.625rem",
                              border: "1px solid #80808080",
                            }}
                          >
                            <h1
                              style={{
                                fontSize: "0.625rem",
                                fontWeight: 600,
                              }}
                            >
                              {alert.alert_name}
                            </h1>
                            <p
                              style={{
                                fontSize: "0.625rem",
                                fontWeight: 400,
                                paddingBottom: "0.62rem",
                                borderBottom: "1px solid #80808080",
                              }}
                            >
                              {alert.description}
                            </p>
                            {alert.to_price && (
                              <p
                                style={{
                                  fontSize: "0.625rem",
                                  fontWeight: 600,
                                }}
                              >
                                Price: <span>{alert.action}</span>{" "}
                                <span style={{ color: "#00BA77" }}>
                                  {alert.to_price}
                                </span>
                              </p>
                            )}
                            {alert.to_volume && (
                              <p
                                style={{
                                  fontSize: "0.625rem",
                                  fontWeight: 600,
                                }}
                              >
                                Volume: <span>{alert.action}</span>{" "}
                                <span style={{ color: "#00BA77" }}>
                                  {alert.to_volume}
                                </span>
                              </p>
                            )}
                            <p
                              style={{
                                fontSize: "0.625rem",
                                fontWeight: 600,
                              }}
                            >
                              Schedule:{" "}
                              <span style={{ color: "#00BA77" }}>
                                {alert.frequency}
                              </span>
                            </p>
                            <button
                              onClick={() => setPopoverId(null)}
                              style={{
                                width: "100%",
                                background: "#00BA77",
                                color: "white",
                                border: "none",
                              }}
                            >
                              close
                            </button>
                          </div>
                        }
                      >
                        <svg
                          onClick={() => {
                            if (alert.id === popoverID) {
                              setPopoverId(null);
                            } else {
                              setPopoverId(alert.id);
                            }
                            // setIsPopoverOpen(!isPopoverOpen)
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                        >
                          <path
                            d="M9.50004 17.4168C13.8542 17.4168 17.4167 13.8543 17.4167 9.50016C17.4167 5.146 13.8542 1.5835 9.50004 1.5835C5.14587 1.5835 1.58337 5.146 1.58337 9.50016C1.58337 13.8543 5.14587 17.4168 9.50004 17.4168Z"
                            stroke="#CFCFCF"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.5 6.3335V10.2918"
                            stroke="#CFCFCF"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.49561 12.6665H9.50272"
                            stroke="#CFCFCF"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Popover>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeIcon={closeIcon}
        styles={{ borderRadius: "0.3125rem" }}
      >
        <div style={{ padding: "2.25rem 4rem" }}>
          <p>Do you want to delete this alert?</p>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
            <button onClick={() => {
              if (loading === false) {
                deleteAlert(id);
                setOpen(false)
              }
            }} style={{ borderRadius: "0.3125rem", backgroundColor: "#FF5348", border: "1px solid #FF5348", color: "white" }}>Delete</button>
            <button onClick={onCloseModal} style={{ borderRadius: "0.3125rem", backgroundColor: "#F5F5F5", border: "1px solid black" }}>Cancel</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AlertHistory;
