/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./proofofid.css";
import DocumentUpload from "../../assets/images/document-upload.svg";
import axios from "../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { MdKeyboardBackspace } from "react-icons/md";

const ProofOfID = () => {
  const [loading, setLoading] = useState(false);
  const [imageBase64, setImageBase64] = useState("");
  const [file, setFile] = useState(null);
  const [idProof, setIdProof] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  console.log(imageBase64.length);

  useEffect(() => {
    axios
      .get("auth/profile/")
      .then((res) => {
        console.log(res);
        if (
          res.data.detail.proof_of_id !== null &&
          res.data.detail.proof_of_id !== ""
        ) {
          setIdProof(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "black",
        color: "white",
      },
    });
  };

  const uploadIdProof = async () => {
    setLoading(true);
    setOpen(true);
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";
    let formdata = new FormData();
    if (file) {
      formdata.append("proof_of_id", file);
      formdata.append("device_type", deviceType);
    }
    try {
      axios
        .put("auth/profile/", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
          setOpen(false);
          setRefresh(!refresh);
          dispatch({ type: "refresh" });
          setImageBase64("");
          showRequestSuccess("Proof of id has been uploaded successfully");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showRequestFailed("Failed to upload proof of id");
          setOpen(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 700 && file) {
      uploadIdProof();
    }
  }, [file]);

  function handleFileUpload(event) {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        showRequestFailed(
          "File size is too big, please choose a smaller file."
        );
      } else {
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64String = e.target.result;
          setImageBase64(base64String);
        };

        reader.readAsDataURL(file);
        setFile(file);
      }
    }
  }
  const handleTabClick = (path) => {
    dispatch({ type: "setProfileComp", payload: path });

    if (path === "/accountinformation") {
      dispatch({
        type: "setAccountInformation",
        payload: "accountInformation",
      });
    } else if (path === "/payment") {
      dispatch({ type: "setAddBank", payload: false });
    } else if (path === "/privacysecurity") {
      dispatch({ type: "setChangePassword", payload: false });
    }
  };
  const onCloseModal = () => setOpen(false);
  const closeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M9.85397 7.50067L13.8545 11.5012C14.1143 11.761 14.1143 12.183 13.8545 12.4429L12.4429 13.8545C12.183 14.1144 11.7617 14.1144 11.5012 13.8545L7.5 9.85398L3.49884 13.8545C3.23897 14.1144 2.81765 14.1144 2.55778 13.8545L1.14553 12.4429C0.885654 12.183 0.885654 11.7617 1.14553 11.5012L5.14668 7.50067L1.14553 3.49951C0.885654 3.23964 0.885654 2.81767 1.14553 2.55779L2.55844 1.1462C2.81831 0.886325 3.23962 0.886325 3.4995 1.1462L7.5 5.14736L11.5012 1.1462C11.761 0.886325 12.1823 0.886325 12.4429 1.1462L13.8545 2.55845C14.1143 2.81833 14.1143 3.23964 13.8545 3.50017L9.85397 7.50067Z"
        fill="#151515"
      />
    </svg>
  );

  return (
    // <div className="rounded-card">
    <div className="proofIdCard !justify-start relative">
      <button
        className="absolute top-1 left-2"
        onClick={() => handleTabClick("/accountinformation")}
      >
        <MdKeyboardBackspace />
      </button>
      <ToastContainer />
      <div className="proofCard">
        <div className="proofIdHeading">
          <h3>Proof of ID</h3>
        </div>
        <div className="proofIdMid">
          <div className="proofIdText1">
            <h4>
              Upload your ID (Driver’s License, International Passport, or
              National ID)
            </h4>
          </div>
          <div className="proofIdText2">
            <h4>Upload document</h4>
          </div>
          {imageBase64.length > 1 || file ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <img
                src={imageBase64}
                alt=""
                style={{ width: "100%", height: "300px" }}
              />
              <button
                disabled={loading || idProof}
                onClick={uploadIdProof}
                style={{
                  background: "#02915d",
                  cursor: "pointer",
                  color: "white",
                  border: "none",
                  padding: "15px 50px",
                  borderRadius: "5px",
                }}
              >
                {loading ? "Uploading..." : "Save"}
              </button>
            </div>
          ) : idProof ? (
            <p style={{ fontWeight: 700, fontSize: "25px" }}>
              To Update your proof of id, please contact support.
            </p>
          ) : (
            <label htmlFor="addressProof" className="uploadBrowser">
              <p>Browse to upload</p>
              <img src={DocumentUpload} alt="DocumentUpload" />
            </label>
          )}
          <input
            onChange={(e) => handleFileUpload(e)}
            type="file"
            id="addressProof"
            style={{ display: "none" }}
          />
          <p>( Maximum upload size: 5MB)</p>
        </div>
        <div className="proofIdBottom">
          <h3>Follow these steps:</h3>
          <p>1. Ensure that your views are accurate</p>
          <p>2. Ensure the lighting is good</p>
          <p>3. Make sure you capture all the edges in the frame </p>
        </div>
      </div>
      {window.innerWidth < 700 && (
        <Modal
          open={open}
          onClose={onCloseModal}
          center
          closeIcon={closeIcon}
          styles={{ borderRadius: "0.3125rem" }}
        >
          <div
            style={{
              padding: "2.25rem 4rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="128"
              height="128"
              viewBox="0 0 24 24"
            >
              <g
                fill="none"
                stroke="#02915d"
                stroke-linecap="round"
                stroke-width="2"
              >
                <path
                  stroke-dasharray="2 4"
                  stroke-dashoffset="6"
                  d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3"
                >
                  <animate
                    attributeName="stroke-dashoffset"
                    dur="0.6s"
                    repeatCount="indefinite"
                    values="6;0"
                  />
                </path>
                <path
                  stroke-dasharray="30"
                  stroke-dashoffset="30"
                  d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21"
                >
                  <animate
                    fill="freeze"
                    attributeName="stroke-dashoffset"
                    begin="0.1s"
                    dur="0.3s"
                    values="30;0"
                  />
                </path>
                <path
                  stroke-dasharray="10"
                  stroke-dashoffset="10"
                  d="M12 16v-7.5"
                >
                  <animate
                    fill="freeze"
                    attributeName="stroke-dashoffset"
                    begin="0.5s"
                    dur="0.2s"
                    values="10;0"
                  />
                </path>
                <path
                  stroke-dasharray="6"
                  stroke-dashoffset="6"
                  d="M12 8.5l3.5 3.5M12 8.5l-3.5 3.5"
                >
                  <animate
                    fill="freeze"
                    attributeName="stroke-dashoffset"
                    begin="0.7s"
                    dur="0.2s"
                    values="6;0"
                  />
                </path>
              </g>
            </svg>
            <p style={{ textAlign: "center" }}>Uploading...</p>
          </div>
        </Modal>
      )}
    </div>
    // </div>
  );
};

export default ProofOfID;
