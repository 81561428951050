import React from 'react'
import "./loading.css"

function Loading() {
    return (
        <div class="loading-container">
            <div class="loading-animation"></div>
        </div>
    )
}

export default Loading