import React, { useEffect, useState } from "react";
import "./wallettable.css";
import { FiSearch } from "react-icons/fi";
import upDown from "../../assets/images/upDown.svg";
import prev from "../../assets/images/prev.svg";
import next from "../../assets/images/next.svg";
import start from "../../assets/images/start.svg";
import end from "../../assets/images/end.svg";
import { HiChevronDown } from "react-icons/hi2";
import { LuFilter } from "react-icons/lu";
import axios from "../../axios";

const covertDate = (dateString) => {
  const date = new Date(dateString);

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  const numericDate = date.toLocaleString("en-US", options);
  // console.log(numericDate);
  return numericDate;
};

const WalletTable = (props) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (props.traderEmail) {
      axios
        .get(`portfolio/transactions/support/${props.traderEmail}/`)
        .then((res) => {
          console.log(res);
          setTableData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {

      axios
        .get("portfolio/transactions/")
        .then((res) => {
          console.log(res);
          setTableData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.traderEmail]);

  const filteredData = tableData.filter(
    (item) =>
      item.transaction_type?.toLowerCase().includes(search.toLowerCase()) ||
      item.channel?.toLowerCase().includes(search.toLowerCase()) ||
      item.status?.toLowerCase().includes(search.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentItems = filteredData.slice(startIndex, endIndex);

  const sortedCurrentItems = currentItems.slice().sort((a, b) => {
    if (sortColumn) {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortDirection === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
      } else {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      }
    }
    return 0;
  });

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSort = (column) => {
    setIsFilter(false);
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const filterOptions = ["date", "type", "channel", "amount", "status"];
  return (
    <div className="walletTableContainer">
      <div className="walletTableHeader">
        <div className="walletHeaderLeft">
          <p>Transaction History</p>
        </div>

        <div className="walletHeaderRight">
          <div className="walletSearchBar">
            <FiSearch color="#838383" />
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
            />
          </div>
          <div onClick={() => setIsFilter(!isFilter)} className="selectFilter">
            <LuFilter color="black" />
            <p>Filters</p>
            <HiChevronDown color="black" style={{ fontSize: "12px" }} />
            {isFilter && (
              <div className="dropdownFilter">
                {filterOptions.map((item, index) => (
                  <div
                    onClick={() => handleSort(item)}
                    key={index}
                    className="filterOption"
                  >
                    {item.charAt(0).toUpperCase() + item.slice(1)}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="walletTableWrapper">
        <table className="walletTable" responsive hover>
          <thead>
            <tr>
              <th onClick={() => handleSort("date")}>
                Date & Time <img src={upDown} alt="upDown" />
              </th>
              <th onClick={() => handleSort("type")}>
                Type <img src={upDown} alt="upDown" />
              </th>
              <th onClick={() => handleSort("channel")}>
                Channel <img src={upDown} alt="upDown" />
              </th>
              <th onClick={() => handleSort("amount")}>
                Amount <img src={upDown} alt="upDown" />
              </th>
              <th onClick={() => handleSort("status")}>
                Status <img src={upDown} alt="upDown" />
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedCurrentItems.map((row, index) => (
              <tr key={index}>
                <td>{covertDate(row.date)}</td>
                <td>{row.transaction_type}</td>
                <td>{row.channel}</td>
                <td>
                  {row.transaction_type.toLowerCase().includes("received") ||
                    row.transaction_type.toLowerCase().includes("deposit") ? (
                    <p className="textSuccess">{row.amount}</p>
                  ) : (
                    <p className="textFailed">{row.amount}</p>
                  )}
                </td>

                <td>
                  {row.status === "completed" || row.status === "approved" ? (
                    <div className="bgSuccess">
                      <p>
                        {row.status?.charAt(0).toUpperCase() +
                          row.status?.slice(1)}
                      </p>
                    </div>
                  ) : row.status === "unprocessed" ? (
                    <div className="bgPending">
                      <p>
                        {row.status?.charAt(0).toUpperCase() +
                          row.status?.slice(1)}
                      </p>
                    </div>
                  ) : (
                    <div className="bgFailed">
                      <p>
                        {row.status?.charAt(0).toUpperCase() +
                          row.status?.slice(1)}
                      </p>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination controls */}
      <div className="paginationContainer">
        <div className="pages">
          <span>{`Page ${currentPage} of ${totalPages} (${filteredData.length} items)`}</span>
        </div>
        <div className="pagination">
          <button
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          >
            <img src={start} alt="start" />
          </button>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <img src={prev} alt="prev" />
          </button>

          {/* Show the first page */}
          <button
            onClick={() => handlePageChange(1)}
            className={currentPage === 1 ? "active" : ""}
          >
            1
          </button>

          {/* Show ellipses if there are more than two pages */}
          {totalPages > 2 && currentPage > 2 && <span>...</span>}

          {/* Show the current page */}
          {currentPage > 1 && currentPage < totalPages && (
            <button
              onClick={() => handlePageChange(currentPage)}
              className="active"
            // style={{ backgroundColor: "green" }} // Added style for the green background
            >
              {currentPage}
            </button>
          )}

          {/* Show the next page */}
          {totalPages > 1 && currentPage < totalPages && (
            <button onClick={() => handlePageChange(currentPage + 1)}>
              {currentPage + 1}
            </button>
          )}

          {/* Show the last page */}
          {totalPages > 1 && currentPage < totalPages - 1 && <span>...</span>}
          {totalPages !== 1 && currentPage + 1 !== totalPages && (
            <button
              onClick={() => handlePageChange(totalPages)}
              className={currentPage === totalPages ? "active" : ""}
            >
              {totalPages}
            </button>
          )}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <img src={next} alt="next" />
          </button>
          <button
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            <img src={end} alt="end" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WalletTable;
