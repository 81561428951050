/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./durationselection.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const DurationSelection = () => {
  return (
    <div className="rounded-card-container">
      <Helmet>
        <script src="//fw-cdn.com/11595992/4245246.js" chat="true"></script>
        <script>
          {`
            window.fwSettings = {
              widget_id: 153000001831,
            };
            !(function () {
              if ("function" != typeof window.FreshworksWidget) {
                var n = function () {
                  n.q.push(arguments);
                };
                (n.q = []), (window.FreshworksWidget = n);
              }
            })();
          `}
        </script>
        <script
          type="text/javascript"
          src="https://widget.freshworks.com/widgets/153000001831.js"
          async
          defer
        ></script>
      </Helmet>
      <div className="rounded-card">
        <div className="durationSelectionCard">
          <div className="durationSelectionText">
            <p>How long do you want to lock savings?</p>
          </div>
          <div className="durationCards">
            <Link
              to={"/wallet/savinglock/durationselection/dayslock?duration=1"}
              className="durationCard"
            >
              <a>30 days</a>
              <div className="percentage">
                <p>5% p/a</p>
              </div>
            </Link>
            <Link
              to={"/wallet/savinglock/durationselection/dayslock?duration=2"}
              className="durationCard">
              <a>60 days</a>
              <div className="percentage">
                <p>5% p/a</p>
              </div>
            </Link>
            <Link
              to={"/wallet/savinglock/durationselection/dayslock?duration=4"}
              className="durationCard">
              <a>120 days</a>
              <div className="percentage">
                <p>5% p/a</p>
              </div>
            </Link>
            <Link
              to={"/wallet/savinglock/durationselection/dayslock?duration=12"}
              className="durationCard">
              <a>1 year</a>
              <div className="percentage">
                <p>5% p/a</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DurationSelection;
