import React, { useEffect, useState } from 'react'
import './payment.css'
import BankIcon from '../../assets/images/bank.svg'
// import EditIcon from '../../assets/images/edit-2.svg'
import AddIcon from '../../assets/images/add.svg'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'
import AddBankDetail from '../AddBankDetail/AddBankDetail'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../axios'

const Payment = () => {
        const addBank = useSelector(state => state.page.addBank)
        const refresh = useSelector(state => state.refresh)
        const dispatch = useDispatch()
        const [available, setAvailable] = useState(true)
        const [details, setDetails] = useState({
                bank: "",
                number: ""
        })
        const [message] = useState("")

        const handleAddBankComp = () => {
                dispatch({ type: 'setAddBank', payload: true })
        }

        useEffect(() => {
                axios.get("auth/profile/").then(res => {
                        console.log(res)
                        if (res.data.detail.bank_name !== null && res.data.detail.bank_name !== "" && res.data.detail.account_number !== null && res.data.detail.account_number !== "") {
                                setAvailable(true)
                                setDetails({ bank: res.data.detail.bank_name, number: res.data.detail.account_number })
                        } else {
                                setAvailable(false)
                        }

                }).catch(err => {
                        console.log(err)
                });
        }, [refresh])

        return (
                <>
                        {addBank ? (
                                <AddBankDetail />
                        ) : (
                                <div className='profilePaymentCard !max-w-[580px] !mx-auto'>
                                        {available &&
                                        <>
                                                <div className='addressForPayment !py-3'>
                                                        <div className='bankAddressLeft'>
                                                                <img src={BankIcon} className='!w-4 !h-4' alt='BankIcon' />
                                                                <p className='!text-sm'>{details.bank}</p>
                                                        </div>
                                                        <div className='bankAddressRight'>
                                                               
                                                        </div>
                                                </div>

                                                <div className='addressForPayment'>
                                                        <div className='bankAddressLeft'>
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M8.0013 2.66675C8.70855 2.66675 9.38682 2.9477 9.88692 3.4478C10.387 3.94789 10.668 4.62617 10.668 5.33341C10.668 6.04066 10.387 6.71894 9.88692 7.21903C9.38682 7.71913 8.70855 8.00008 8.0013 8.00008C7.29406 8.00008 6.61578 7.71913 6.11568 7.21903C5.61559 6.71894 5.33464 6.04066 5.33464 5.33341C5.33464 4.62617 5.61559 3.94789 6.11568 3.4478C6.61578 2.9477 7.29406 2.66675 8.0013 2.66675ZM8.0013 9.33341C10.948 9.33341 13.3346 10.5267 13.3346 12.0001V13.3334H2.66797V12.0001C2.66797 10.5267 5.05464 9.33341 8.0013 9.33341Z" fill="#00BA77"/>
                                                                </svg>

                                                                <div className="">
                                                                        <p className="!text-[10px] text-[#16161699] opacity-60">Account Number</p>
                                                                        <p className='!text-sm'>{details?.number?.split("")?.map((x,i)=>{if (i<4){ return "*"}else{ return x} })?.join("")}</p>
                                                                </div>
                                                        </div>

                                                </div>

                                                {/* <button className="w-full py-3 rounded bg-[#B3B3B3] transition !mx-0"><p className="text-center">Save</p></button> */}
                                                <p className="-mt-4 text-sm"><a href="mailto:teamgohamma@gmail.com" className="!text-green-500 hover:underline">Contact support</a> to change your payment account.</p>
                                        </>
                                        }
                                        {!available &&
                                                <Link
                                                        onClick={handleAddBankComp}
                                                        to='/payment/addbank'
                                                        className='bankAccountDetailCard'
                                                >
                                                        <div className='bankAccountDetailLeft'>
                                                                <img src={AddIcon} alt='AddIcon' />
                                                                <p>Add Bank account details</p>
                                                        </div>
                                                        <div className='bankAccountDetailRight'>
                                                                <IoIosArrowForward />
                                                        </div>
                                                </Link>
                                        }
                                        <p style={{ textAlign: "center", fontWeight: 700, fontSize: "25px" }}>{message}</p>
                                </div>
                        )}
                </>
        )
}

export default Payment
