/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";
import "./buysellpopupoverlay.css";
import { IoCloseSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../axios";
import { v4 as uuidv4 } from "uuid";

const tofix = (num) => {
  // Round down to two decimal places
  let roundedNumber = Math.floor(num * 100) / 100;

  // Add commas to the integer part
  let formattedNumber = roundedNumber
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formattedNumber;
};

const BuySellPopUpOverlay = ({
  popup,
  orderType,
  openMessage,
  sl,
  tp,
  settp,
  setsl,
  setOpenMessage,
  tif,
  limitPrice,
  refresh,
  setRefresh,
  handlePopup,
  setPopup,
  value,
  symbol,
  setActiveButton,
  currentPrice,
}) => {
  // const dispatch = useDispatch();
  // const liveDemo = useSelector((state) => state.page.liveDemo);
  const [executeLoading, setExecuteLoading] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [openNote1, setOpenNote1] = useState(false);
  const id = uuidv4();
  const [isActive, setIsActive] = useState(false);
  const [isPublicHoliday, setIsPublicHoliday] = useState(false);
  const account = useSelector((state) => state.account);
  const [currentCompetiton, setCurrentCompetition] = useState("Not Started");

  // console.log(window.location.pathname);
  // console.log(isActive);
  // console.log(currentCompetiton);
  // console.log(isPublicHoliday);
  // console.log(account);

  // useEffect(() => {
  //   const checkTimeAndDay = () => {
  //     const now = new Date();
  //     const day = now.getDay(); // 0 (Sunday) to 6 (Saturday)
  //     const hour = now.getHours();
  //     const minutes = now.getMinutes();
  //     console.log(day)
  //     console.log(hour)
  //     console.log(minutes)

  //     const isWeekday = day >= 1 && day <= 5;
  //     const isWithinTimeRange = (hour > 10 ) && (hour < 14 || (hour === 14 && minutes <= 30));

  //     console.log(isWeekday)
  //     console.log(isWithinTimeRange)

  //     setIsActive(isWeekday && isWithinTimeRange);
  //   };

  //   // Call the function initially
  //   checkTimeAndDay();

  //   // Update every minute
  //   const interval = setInterval(checkTimeAndDay, 60000);

  //   // Cleanup
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    axios.get("leaderboard/currentcompetition/").then((res) => {
      console.log(res);

      // Parse the input date string
      const inputDate = new Date(res.data.start_date);
      const endDate = new Date(res.data.end_date);

      // Get the current date
      const currentDate = new Date();

      // Compare the input date with the current date

      if (
        (inputDate > currentDate && endDate <= currentDate) ||
        res.data.status === "closed"
      ) {
        setCurrentCompetition("Not Started");
      } else {
        setCurrentCompetition("Started");
      }
      // localStorage.setItem("comp_id", res.data.id);
    });
  }, []);

  useEffect(() => {
    const shouldSetState = () => {
      const now = new Date();
      const day = now.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
      const hour = now.getHours();
      const minutes = now.getMinutes();

      // Check if it's between Monday (1) and Friday (5)
      const isWeekday = day >= 1 && day <= 5;

      // Check if the time is between 10:00 AM and 2:30 PM
      const isWithinTimeRange =
        (hour > 10 || (hour === 10 && minutes >= 0)) &&
        (hour < 14 || (hour === 14 && minutes <= 30));

      return isWeekday && isWithinTimeRange;
    };

    function isTodayInDateList() {
      // List of dates as strings in "Month Day" format
      const dateList = [
        "January 1",
        "March 29",
        "April 1",
        "April 10",
        "April 11",
        "May 1",
        "June 12",
        "June 17",
        "June 18",
        "September 16",
        "October 1",
        "December 25",
        "December 26",
      ];

      // Get today's date
      const today = new Date();

      // Array of month names to convert month number to month name
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Format today's date to match the format in the dateList
      const todayStr = `${monthNames[today.getMonth()]} ${today.getDate()}`;

      // Check if today's date is in the list
      return dateList.includes(todayStr);
    }

    setIsActive(shouldSetState());
    setIsPublicHoliday(isTodayInDateList());

    // Optionally, you could set up an interval to re-check the condition periodically
    const intervalId = setInterval(() => {
      setIsActive(shouldSetState());
    }, 60000); // Check every minute

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "black",
        color: "white",
      },
    });
  };

  const executeTrade = async () => {
    console.log(symbol);
    setExecuteLoading(true);
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";

    if (
      currentCompetiton === "Not Started" &&
      account.acc_type === "competition_account"
    ) {
      buttonClick("Trade execution button");
      showRequestFailed(
        "There is currently no ongoing competiton, please switch to a live account or demo account to execute a trade."
      );
    } else {
      if (orderType === "limit") {
        try {
          axios
            .post(`/execution/demo/trade/${account.acc_type}/${account.id}/`, {
              symbol: symbol,
              quantity: value * 100000,
              action: popup,
              order_type: "limit",
              take_profit: tp ? tp : tp === "" ? null : null,
              stop_loss: sl ? sl : sl === "" ? null : null,
              order_id: id,
              price: limitPrice,
              time_in_force: tif || "GTC",
              device_type: deviceType,
            })
            .then((res) => {
              console.log(res);
              setExecuteLoading(false);
              setRefresh(!refresh);
              settp(null);
              setsl(null);
              // alert("Trade execution was successful.")
              showRequestSuccess("Trade execution was successful.");
              // dispatch({ type: "setConfirm", payload: false })
              setPopup(null);
              setOpenMessage(true);
            })
            .catch((err) => {
              console.log(err);
              setExecuteLoading(false);
              // settp(null);
              // setsl(null);
              if (err.response.data.error) {
                setExecuteLoading(false);
                showRequestFailed(err.response.data.error);
              } else {
                setExecuteLoading(false);
                showRequestFailed("Trade execution failed.");
              }
            });
        } catch (error) {
          console.log(error);
          if (error.response.data.error) {
            setExecuteLoading(false);
            showRequestFailed(error.response.data.error);
          } else {
            setExecuteLoading(false);
            showRequestFailed("Trade execution failed.");
          }
        }
      } else {
        try {
          axios
            .post(`/execution/demo/trade/${account.acc_type}/${account.id}/`, {
              symbol: symbol,
              quantity: value * 100000,
              action: popup,
              order_type: "market",
              take_profit: tp ? tp : tp === "" ? null : null,
              stop_loss: sl ? sl : sl === "" ? null : null,
              order_id: id,
              device_type: deviceType,
            })
            .then((res) => {
              console.log(res);
              setExecuteLoading(false);
              setRefresh(!refresh);
              settp(null);
              setsl(null);
              // alert("Trade execution was successful.")
              showRequestSuccess("Trade execution was successful.");
              // dispatch({ type: "setConfirm", payload: false })
              setPopup(null);
              setOpenMessage(true);
            })
            .catch((err) => {
              console.log(err);
              // settp(null);
              // setsl(null);
              if (err.response.data.error) {
                setExecuteLoading(false);
                showRequestFailed(err.response.data.error);
              } else {
                setExecuteLoading(false);
                showRequestFailed("Trade execution failed.");
              }
            });
        } catch (error) {
          console.log(error);
          setExecuteLoading(false);
          showRequestFailed("Trade execution failed.");
        }
      }
    }
  };

  // const handleConfirm = () => {
  //   setActiveButton(popup)
  //   setPopup(null);
  //   dispatch({ type: "setConfirm", payload: true });
  // };
  const buttonClick = async (buttonName) => {
    // console.log(buttonName)
    // console.log(page.toLowerCase)
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";
    try {
      axios
        .post("/portfolio/button-click/", {
          button_name: `${buttonName}`,
          page: window.location.pathname.split("/")[1] || "home",
          device_type: deviceType,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {popup && <div className="overlayMarkets" />}
      {popup && (
        <div>
          <ToastContainer />
          <div className="popup">
            <IoCloseSharp
              onClick={() => setPopup(null)}
              className="closePopup"
            />
            <p
              className={`head ${popup === "buy" ? "greenColor" : "redColor"}`}
            >
              {popup === "buy" ? "Confirm Buy" : "Confirm Sell"}
            </p>
            <div
              style={{
                backgroundColor: `${
                  popup === "buy" ? "#00BA770D" : "#ff53480D"
                }`,
                borderRadius: "6px",
                width: "80%",
                padding: "16px 19px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                  }}
                >
                  <p>Stock</p>
                  <p
                    style={{
                      color: `${popup === "buy" ? "#00BA77" : "#ff5348"}`,
                      fontWeight: 500,
                    }}
                  >
                    {symbol}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                  }}
                >
                  <p>No of Unit</p>
                  <p
                    style={{
                      color: `${popup === "buy" ? "#00BA77" : "#ff5348"}`,
                      fontWeight: 500,
                    }}
                  >
                    {(value * 100000).toLocaleString()} units
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                  }}
                >
                  <p>Order Type</p>
                  <p
                    style={{
                      color: `${popup === "buy" ? "#00BA77" : "#ff5348"}`,
                      fontWeight: 500,
                    }}
                  >
                    {orderType}
                  </p>
                </div>
                {tp && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "12px",
                    }}
                  >
                    <p>Take Profit</p>
                    <p
                      style={{
                        color: `${popup === "buy" ? "#00BA77" : "#ff5348"}`,
                        fontWeight: 500,
                      }}
                    >
                      {tp}
                    </p>
                  </div>
                )}
                {sl && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "12px",
                    }}
                  >
                    <p>Stop Loss</p>
                    <p
                      style={{
                        color: `${popup === "buy" ? "#00BA77" : "#ff5348"}`,
                        fontWeight: 500,
                      }}
                    >
                      {sl}
                    </p>
                  </div>
                )}
                {orderType === "limit" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "12px",
                    }}
                  >
                    <p>Limit Price</p>
                    <p
                      style={{
                        color: `${popup === "buy" ? "#00BA77" : "#ff5348"}`,
                        fontWeight: 500,
                      }}
                    >
                      {limitPrice ? limitPrice : "not provided"}
                    </p>
                  </div>
                )}
                {tif && orderType === "limit" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "12px",
                    }}
                  >
                    <p>Time in force</p>
                    <p
                      style={{
                        color: `${popup === "buy" ? "#00BA77" : "#ff5348"}`,
                        fontWeight: 500,
                      }}
                    >
                      {tif}
                    </p>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                  }}
                >
                  <p>Current cost</p>
                  <p
                    style={{
                      color: `${popup === "buy" ? "#00BA77" : "#ff5348"}`,
                      fontWeight: 500,
                    }}
                  >
                    N{(currentPrice * value * 100000).toLocaleString()}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      margin: 0,
                      alignItems: "start",
                      gap: "2px",
                    }}
                  >
                    Hamma Fees{" "}
                    <span
                      onClick={() => {
                        setOpenNote1(false);
                        setOpenNote(!openNote);
                      }}
                      style={{ position: "relative" }}
                    >
                      <svg
                        style={{ marginTop: "2px" }}
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.00033 12.8337C10.2087 12.8337 12.8337 10.2087 12.8337 7.00033C12.8337 3.79199 10.2087 1.16699 7.00033 1.16699C3.79199 1.16699 1.16699 3.79199 1.16699 7.00033C1.16699 10.2087 3.79199 12.8337 7.00033 12.8337Z"
                          stroke={`${popup === "buy" ? "#00BA77" : "#ff5348"}`}
                          stroke-width="0.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7 4.66699V7.58366"
                          stroke={`${popup === "buy" ? "#00BA77" : "#ff5348"}`}
                          stroke-width="0.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.99658 9.33301H7.00182"
                          stroke={`${popup === "buy" ? "#00BA77" : "#ff5348"}`}
                          stroke-width="0.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {openNote && (
                        <div
                          style={{
                            backgroundColor: "white",
                            width: "120px",
                            height: "40px",
                            padding: "0 5px",
                            overflow: "hidden",
                            position: "absolute",
                            left: 18,
                            top: 5,
                            borderRadius: "0px 5px 5px 5px",
                          }}
                        >
                          <p style={{ fontSize: "10px", color: "#646464" }}>
                            Hamma charges a fix fee of N200 per Trade.
                          </p>
                          {/* <p style={{ fontSize: "8px", color: "#AEAEAE" }}>SEC Fees (0.3%), CSCS Fees (0.06%), Stamp Duty (0.075%)</p> */}
                        </div>
                      )}
                    </span>
                  </p>
                  <p
                    style={{
                      color: `${popup === "buy" ? "#00BA77" : "#ff5348"}`,
                      fontWeight: 500,
                    }}
                  >
                    N200
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      margin: 0,
                      alignItems: "start",
                      gap: "2px",
                    }}
                  >
                    Third party Fees{" "}
                    <span
                      onClick={() => {
                        setOpenNote(false);
                        setOpenNote1(!openNote1);
                      }}
                      style={{ position: "relative" }}
                    >
                      <svg
                        style={{ marginTop: "2px" }}
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.00033 12.8337C10.2087 12.8337 12.8337 10.2087 12.8337 7.00033C12.8337 3.79199 10.2087 1.16699 7.00033 1.16699C3.79199 1.16699 1.16699 3.79199 1.16699 7.00033C1.16699 10.2087 3.79199 12.8337 7.00033 12.8337Z"
                          stroke={`${popup === "buy" ? "#00BA77" : "#ff5348"}`}
                          stroke-width="0.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7 4.66699V7.58366"
                          stroke={`${popup === "buy" ? "#00BA77" : "#ff5348"}`}
                          stroke-width="0.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.99658 9.33301H7.00182"
                          stroke={`${popup === "buy" ? "#00BA77" : "#ff5348"}`}
                          stroke-width="0.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {openNote1 && (
                        <div
                          style={{
                            backgroundColor: "white",
                            width: "120px",
                            height: "55px",
                            padding: "0 5px",
                            overflow: "hidden",
                            position: "absolute",
                            left: 18,
                            top: 5,
                            borderRadius: "0px 5px 5px 5px",
                          }}
                        >
                          <p style={{ fontSize: "10px", color: "#646464" }}>
                            SEC Fees (0.3%), CSCS Fees (0.06%), Stamp Duty
                            (0.075%)
                          </p>
                          {/* <p style={{ fontSize: "8px", color: "#AEAEAE" }}>SEC Fees (0.3%), CSCS Fees (0.06%), Stamp Duty (0.075%)</p> */}
                        </div>
                      )}
                    </span>
                  </p>
                  <p
                    style={{
                      color: `${popup === "buy" ? "#00BA77" : "#ff5348"}`,
                      fontWeight: 500,
                    }}
                  >
                    N
                    {tofix(
                      currentPrice * value * 100000 * 0.003 +
                        currentPrice * value * 100000 * 0.0006 +
                        currentPrice * value * 100000 * 0.00075
                    )}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                    marginBottom: "15px",
                  }}
                >
                  <p>Total cost</p>
                  <p
                    style={{
                      color: `${popup === "buy" ? "#00BA77" : "#ff5348"}`,
                      fontWeight: 500,
                    }}
                  >
                    N
                    {tofix(
                      currentPrice * value * 100000 +
                        200 +
                        currentPrice * value * 100000 * 0.003 +
                        currentPrice * value * 100000 * 0.0006 +
                        currentPrice * value * 100000 * 0.00075
                    )}
                  </p>
                </div>
              </div>
              <div style={{ borderTop: "1px solid #0000001A" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                    marginTop: "15px",
                    marginBottom: "30px",
                  }}
                >
                  <p>From</p>
                  <p
                    style={{
                      color: `${popup === "buy" ? "#00BA77" : "#ff5348"}`,
                      fontWeight: 500,
                    }}
                  >
                    Wallet
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                  }}
                >
                  <p style={{ whiteSpace: "nowrap" }}>Order no</p>
                  <p
                    style={{
                      color: `${popup === "buy" ? "#00BA77" : "#ff5348"}`,
                      fontWeight: 500,
                      textAlign: "end",
                    }}
                  >
                    {id}
                  </p>
                </div>
              </div>
            </div>
            {/* <p className="lot">( {value} Lot )</p> */}
            {
              <button
                onClick={() => {
                  // if (
                  //   executeLoading === false &&
                  //   isActive &&
                  //   !isPublicHoliday
                  // ) {
                  //   buttonClick("Trade execution button");
                  //   executeTrade();
                  // } else if (isPublicHoliday) {
                  //   showRequestFailed("Can't execute trade on public holidays");
                  // } else {
                  //   buttonClick("Trade execution button");
                  //   showRequestFailed(
                  //     "Market opens at 10am and closes at 2:30pm, Monday to Friday"
                  //   );
                  // }
                  buttonClick("Trade execution button");
                  executeTrade();
                }}
                className={` ${popup === "buy" ? "greenBack" : "redBack"}`}
              >
                <p>{executeLoading ? "Please wait..." : "Confirm"}</p>
              </button>
            }

            {/* {<button
              className={` ${popup === "buy" ? "greenBack" : "redBack"}`}
            >
              <p>You can't execute a trade at this time.</p>
            </button>} */}
          </div>
        </div>
      )}
    </>
  );
};

export default BuySellPopUpOverlay;
