import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip)

export const data = {
  labels: ['FHLISH', 'FHLISH', 'FHLISH', 'FHLISH', 'FHLISH', 'FHLISH'],
  datasets: [
    {
      label: ' Votes',
      data: [6, 8, 3, 5, 2],
      backgroundColor: ['#00BA77', '#B9D569', '#E3EC77', '#DEB972', '#DE9077'],
      borderWidth: 1
    }
  ]
}

export default function PieChartHoldings ({labels, data}) {
  const datam = {
    labels,
    datasets: [
      {
        label: 'Shares',
        data,
        backgroundColor: [
          "#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd",
          "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf",
          "#aec7e8", "#ffbb78", "#98df8a", "#ff9896", "#c5b0d5",
          "#c49c94", "#f7b6d2", "#c7c7c7", "#dbdb8d", "#9edae5",
          "#393b79", "#637939", "#8c6d31", "#843c39", "#7b4173",
          "#5254a3", "#637939", "#8c6d31", "#843c39", "#7b4173",
          "#5254a3", "#393b79", "#637939", "#8c6d31", "#843c39",
          "#7b4173", "#5254a3", "#393b79", "#637939", "#8c6d31",
          "#843c39", "#7b4173", "#5254a3", "#393b79", "#637939",
          "#8c6d31", "#843c39", "#7b4173", "#5254a3", "#393b79"
        ],
        borderWidth: 1
      }
    ]
  }
  return <Pie data={datam} />
}
