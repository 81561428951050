/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./support.css";
import { Link } from "react-router-dom";
import faq from "../../assets/faq.svg";

function Support() {
  return (
    <div className="rounded-card-container">
      <div className="rounded-card">
        <div className="support-card">
          <div className="search_container_main">
            <input
              type="text"
              placeholder="Search article"
              className="search_input"
            />
            <div className="search_icon_div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M17.3927 16.92L20.4727 20M19.5837 11.581C19.5837 15.768 16.2007 19.162 12.0287 19.162C7.85566 19.162 4.47266 15.768 4.47266 11.582C4.47266 7.393 7.85566 4 12.0277 4C16.2007 4 19.5837 7.394 19.5837 11.581Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <div className="ticket_main_container">
            <div className="ticket_container">
              <h1>Need help directly from our support team?</h1>
              <p>Send us a ticket and we will get right back to you</p>
            </div>
            <button className="ticket_button">Send a Ticket</button>
          </div>

          <div className="support_grid_main_container">
            <div className="support_grid_container">
              <h1 className="support_grid_heading">FAQ</h1>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <Link to="/support/faq">
                <div className="see_more_container">
                  <p className="see_more_text">See More</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15.037 17.6567L13.626 16.2388L16.896 12.9838L3.29103 12.9707L3.29303 10.9707L16.861 10.9838L13.646 7.75375L15.063 6.34375L20.707 12.0138L15.037 17.6567Z"
                      fill="#00BA77"
                    />
                  </svg>
                </div>
              </Link>
            </div>
            <div className="support_grid_container">
              <h1 className="support_grid_heading">Self-help Guide</h1>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <Link to="/support/self-help">
                <div className="see_more_container">
                  <p className="see_more_text">See More</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15.037 17.6567L13.626 16.2388L16.896 12.9838L3.29103 12.9707L3.29303 10.9707L16.861 10.9838L13.646 7.75375L15.063 6.34375L20.707 12.0138L15.037 17.6567Z"
                      fill="#00BA77"
                    />
                  </svg>
                </div>
              </Link>
            </div>
            <div className="support_grid_container">
              <h1 className="support_grid_heading">Referral Program</h1>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="support_grid_questions">
                <img src={faq} />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <Link to="/support/referral">
                <div className="see_more_container">
                  <p className="see_more_text">See More</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15.037 17.6567L13.626 16.2388L16.896 12.9838L3.29103 12.9707L3.29303 10.9707L16.861 10.9838L13.646 7.75375L15.063 6.34375L20.707 12.0138L15.037 17.6567Z"
                      fill="#00BA77"
                    />
                  </svg>
                </div>
              </Link>
            </div>
          </div>
          <div className="ticket_main_container">
            <div className="ticket_container">
              <h1>Still unable to find your answer?</h1>
              <p>Send us a ticket and we will get right back to you</p>
            </div>
            <button className="ticket_button">Send a Ticket</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
