import React from 'react'
import "./stockPriceLoading.css"

function StockPriceLoading() {
  return (
    <div class="stock-price-loading-container">
        <div class="stock-price-loading-animation"></div>
    </div>
)
}

export default StockPriceLoading