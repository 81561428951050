import React from 'react'
import "./chartLoading.css"

function ChartLoading() {
    return (
        <div class="chart-loading-container">
            <div class="chart-loading-animation"></div>
        </div>
    )
}

export default ChartLoading