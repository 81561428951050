import React, { useEffect, useMemo, useRef, useState } from "react";
import "./alertlefttop.css";
import { Link } from "react-router-dom";

const AlertLeftTop = () => {
        const [isLeftTopScrolling, setIsLeftTopScrolling] = useState(false);
        const alertLeftTopRef = useRef(null);
        const params = useMemo(() => new URLSearchParams(window.location.search), []);

        useEffect(() => {
                const handleScroll = () => {
                        setIsLeftTopScrolling(true);
                };

                const hideScrollbar = () => {
                        setIsLeftTopScrolling(false);
                };

                const alertLeftTopElement = alertLeftTopRef.current;

                alertLeftTopElement.addEventListener("scroll", handleScroll);
                alertLeftTopElement.addEventListener("mouseleave", hideScrollbar);

                return () => {
                        alertLeftTopElement.removeEventListener("scroll", handleScroll);
                        alertLeftTopElement.removeEventListener("mouseleave", hideScrollbar);
                };
        }, []);

        return (
                <div
                        className={`alertLeftTop ${isLeftTopScrolling ? "scrolling" : "notscrolling"
                                }`}
                        ref={alertLeftTopRef}
                >
                        <div className="historyCard flex-wrap gap-2 !justify-center lg:justify-between ">
                                <p>History</p>
                                {!params.get("trader_email") && 
                                <Link to="/alert">
                                        <div className="newChatButton">
                                                <p>New Alert</p>
                                        </div>
                                </Link>}
                        </div>
                        {/* <div className="timeRemaining">
        <p className="date">Last 7 days</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="date">Last 30 days</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="date">Last 30 days</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
        <p className="item">Place a buy trade on Cadbury at 12.7</p>
      </div> */}
                </div>
        );
};

export default AlertLeftTop;
