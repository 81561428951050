/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import './accountinformation.css'
import TickMark from '../../assets/images/tick-circle.svg'
import ArrowRight from '../../assets/images/arrow-right.svg'
import Information from '../../assets/images/information.svg'
import { FaChevronRight } from 'react-icons/fa'
import { ProofOfID, ProofOfAddress, UploadPhotograph } from '../../components'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../axios'

const AccountInformation = () => {
        const dispatch = useDispatch()
        const accountInformation = useSelector(state => state.page.accountInformation)
        const refresh = useSelector(state => state.refresh)
        const [idProof, setIdProof] = useState(false)
        const [addressProof, setAddressProof] = useState(false)
        const [photoProof, setPhotoProof] = useState(false)

        const handleAccountInformation = text => {
                dispatch({ type: 'setAccountInformation', payload: text })
        }



        useEffect(() => {
                axios.get("auth/profile/").then(res => {
                        console.log(res)
                        if (res.data.detail.profile_img !== null && res.data.detail.profile_img !== "") {
                                setPhotoProof(true)
                        } else {
                                setPhotoProof(false)
                        }
                        if (res.data.detail.proof_of_id !== null && res.data.detail.proof_of_id !== "") {
                                setIdProof(true)
                        } else {
                                setIdProof(false)
                        }
                        if (res.data.detail.proof_of_address !== null && res.data.detail.proof_of_address !== "") {
                                setAddressProof(true)
                        } else {
                                setAddressProof(false)
                        }

                }).catch(err => {
                        console.log(err)
                });
        }, [refresh])

        return (
                <>
                        {accountInformation === 'accountInformation' && (
                        <div className='accountInformationContainer  !pt-4'>
                                        <div className='!w-full max-w-[588px] mx-auto px-2 '>
                                                <div className='accountInformationCard '>
                                                        <a>Personal information</a>
                                                        <div className='accountRight'>
                                                                <div className='completeText'>
                                                                        <img src={TickMark} />
                                                                        <p>Completed</p>
                                                                </div>
                                                                <FaChevronRight color={'black'} />
                                                        </div>
                                                </div>
                                                <Link
                                                        to='/accountinformation/proofofid'
                                                        onClick={() => handleAccountInformation('ProofOfID')}
                                                        className='accountInformationCard'
                                                >
                                                        <a>Proof of ID</a>
                                                        <div className='accountRight'>
                                                                {idProof === true ? <div className='completeText'>
                                                                        <img src={TickMark} />
                                                                        <p>Completed</p>
                                                                </div> :
                                                                        <div className='incompleteText'>
                                                                                <img src={Information} />
                                                                                <p>Incomplete</p>
                                                                        </div>
                                                                }
                                                                <FaChevronRight color={'black'} />
                                                        </div>
                                                </Link>
                                                <Link
                                                        to='/accountinformation/proofofaddress'
                                                        onClick={() => handleAccountInformation('ProofOfAddress')}
                                                        className='accountInformationCard'
                                                >
                                                        <a>Proof of address</a>
                                                        <div className='accountRight'>
                                                                {addressProof === true ? <div className='completeText'>
                                                                        <img src={TickMark} />
                                                                        <p>Completed</p>
                                                                </div> :
                                                                        <div className='incompleteText'>
                                                                                <img src={Information} />
                                                                                <p>Incomplete</p>
                                                                        </div>
                                                                }
                                                                <FaChevronRight color={'black'} />
                                                        </div>
                                                </Link>
                                                <Link
                                                        to='/accountinformation/uploadphotograph'
                                                        onClick={() => handleAccountInformation('UploadPhotograph')}
                                                        className='accountInformationCard'
                                                >
                                                        <a>Upload Photograph</a>
                                                        <div className='accountRight'>
                                                                {photoProof === true ? <div className='completeText'>
                                                                        <img src={TickMark} />
                                                                        <p>Completed</p>
                                                                </div> :
                                                                        <div className='incompleteText'>
                                                                                <img src={Information} />
                                                                                <p>Incomplete</p>
                                                                        </div>
                                                                }
                                                                <FaChevronRight color={'black'} />
                                                        </div>
                                                </Link>
                                                <div className='completeAccountInformation'>
                                                        <p>
                                                                Complete your account information to trade and make profits!
                                                        </p>
                                                </div>
                                        </div>
                        </div>
                        )}
                        {accountInformation === 'ProofOfID' && <ProofOfID />}
                        {accountInformation === 'ProofOfAddress' && <ProofOfAddress />}
                        {accountInformation === 'UploadPhotograph' && <UploadPhotograph />}
                </>
        )
}

export default AccountInformation
