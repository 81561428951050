import React from "react";
import "./singleSupport.css";
import { Link, useLocation } from "react-router-dom";
import faq from "../../assets/faq.svg";

function SingleSupport() {
  const { pathname } = useLocation();
  const path = pathname.split("/")[2];
  // console.log(pathname.split("/")[2])
  return (
    <div className="support_main_container">
      <div className="support_container">
        <div className="search_container">
          <input
            type="text"
            placeholder="Search article"
            className="search_input"
          />
          <div className="search_icon_div">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M17.3927 16.92L20.4727 20M19.5837 11.581C19.5837 15.768 16.2007 19.162 12.0287 19.162C7.85566 19.162 4.47266 15.768 4.47266 11.582C4.47266 7.393 7.85566 4 12.0277 4C16.2007 4 19.5837 7.394 19.5837 11.581Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="tags">
          <p>Breadcrumb</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.4703 7.52876C10.5952 7.65378 10.6655 7.82332 10.6655 8.0001C10.6655 8.17687 10.5952 8.34641 10.4703 8.47143L6.69893 12.2428C6.63743 12.3064 6.56387 12.3572 6.48253 12.3922C6.40119 12.4271 6.31371 12.4455 6.22519 12.4463C6.13667 12.447 6.04889 12.4302 5.96696 12.3966C5.88503 12.3631 5.81059 12.3136 5.748 12.251C5.6854 12.1884 5.6359 12.114 5.60238 12.0321C5.56886 11.9501 5.55199 11.8623 5.55276 11.7738C5.55353 11.6853 5.57192 11.5978 5.60686 11.5165C5.6418 11.4352 5.69259 11.3616 5.75626 11.3001L9.05626 8.0001L5.75626 4.7001C5.63482 4.57436 5.56762 4.40596 5.56914 4.23116C5.57066 4.05637 5.64077 3.88916 5.76438 3.76555C5.88799 3.64195 6.05519 3.57183 6.22999 3.57031C6.40479 3.56879 6.57319 3.63599 6.69893 3.75743L10.4703 7.52876Z"
              fill="#161616"
            />
          </svg>
          <p>Breadcrumb</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.4703 7.52876C10.5952 7.65378 10.6655 7.82332 10.6655 8.0001C10.6655 8.17687 10.5952 8.34641 10.4703 8.47143L6.69893 12.2428C6.63743 12.3064 6.56387 12.3572 6.48253 12.3922C6.40119 12.4271 6.31371 12.4455 6.22519 12.4463C6.13667 12.447 6.04889 12.4302 5.96696 12.3966C5.88503 12.3631 5.81059 12.3136 5.748 12.251C5.6854 12.1884 5.6359 12.114 5.60238 12.0321C5.56886 11.9501 5.55199 11.8623 5.55276 11.7738C5.55353 11.6853 5.57192 11.5978 5.60686 11.5165C5.6418 11.4352 5.69259 11.3616 5.75626 11.3001L9.05626 8.0001L5.75626 4.7001C5.63482 4.57436 5.56762 4.40596 5.56914 4.23116C5.57066 4.05637 5.64077 3.88916 5.76438 3.76555C5.88799 3.64195 6.05519 3.57183 6.22999 3.57031C6.40479 3.56879 6.57319 3.63599 6.69893 3.75743L10.4703 7.52876Z"
              fill="#161616"
            />
          </svg>
          <p>Breadcrumb</p>
        </div>
        <div className="single_support_grid_main_container">
          <div className="big_grid">
            <h1 className="single_support_grid_heading">
              {path === "faq"
                ? "FAQs"
                : path === "referral"
                ? "Referral Program"
                : "Self-help Guide"}
            </h1>
            {/* <p className="head_p">
              {path === "faq"
                ? "FAQs"
                : path === "referral"
                ? "Referral Program"
                : "Self-help Guide"}
            </p> */}
            <div>
              <div className="single_support_grid_questions">
                <img src={faq} alt="" />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="single_support_grid_questions">
                <img src={faq} alt="" />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="single_support_grid_questions">
                <img src={faq} alt="" />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="single_support_grid_questions">
                <img src={faq} alt="" />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="single_support_grid_questions">
                <img src={faq} alt="" />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
              <div className="single_support_grid_questions">
                <img src={faq} alt="" />
                <p>
                  This is a placeholder article title. The content of this text
                  should be ignored.
                </p>
              </div>
            </div>
          </div>
          <div className="navigation_container">
            <h1 className="single_support_grid_heading">Nav</h1>
            <div className="support_navs">
              <Link to="/support/faq">
                <p>FAQ</p>
              </Link>
              <Link to="/support/self-help">
                <p>Self-help Guide</p>
              </Link>
              <Link to="/support/referral">
                <p>Referral Program</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="ticket_main_container">
          <div className="ticket_container">
            <h1>Still unable to find your answer?</h1>
            <p>Send us a ticket and we will get right back to you</p>
          </div>
          <button className="ticket_button">Send a Ticket</button>
        </div>
      </div>
    </div>
  );
}

export default SingleSupport;
