import React, { useEffect, useState } from 'react'
import "./signup.css"
import axios from '../../axios'

function Signup() {
    const [otp, setOtp] = useState(new Array(6).fill(""))
    const [register, setRegister] = useState({
        email: "",
        password: "",
        firstname: "",
        lastname: "",
        ref_code: ""
    })

    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [fError, setFError] = useState("")
    const [lError, setLError] = useState("")
    const [mainError, setMainError] = useState("")
    const [loading, setLoading] = useState(false)
    const [viewPassword, setViewPassword] = useState(false)
    const [resent, setResent] = useState(false)

    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // console.log(otp.join('').replace(/,/g, ''))

    useEffect(() => {
        if (window.location.search !== "?otp") {
            localStorage.removeItem("access_token")
            localStorage.removeItem("refresh_token")
            localStorage.removeItem("user_email")
        }
    }, [])

    function handleChange(e, index) {
        if (isNaN(e.target.value)) return false

        setOtp([...otp.map((data, indx) => (indx === index ? e.target.value : data))])

        if (e.target.value && e.target.nextSibling) {
            e.target.nextSibling.focus()
        }
    }

    const Register = async (e) => {
        setLoading(true)
        e.preventDefault()
        if (!pattern.test(register.email)) {
            setEmailError("Email format is incorrect")
        } else if (register.firstname === "") {
            setFError("First name is required")
        } else if (register.lastname === "") {
            setLError("Lastname is required")
        } else if (register.password.length < 8) {
            setPasswordError("Password needs to be 8 characters long")
        } else {
            localStorage.setItem("user_email", register.email)
            axios.post("auth/register/", register).then(res => {
                console.log(res)
                setLoading(false)
                window.location.search = "?otp"
            }).catch(err => {
                console.log(err)
                setLoading(false)
                setMainError("Registeration failed, ensure all details are provided and correct.")
            })
        }

    }

    const ResendToken = async (e) => {
        // e.preventDefault()
        setLoading(true)
        setResent(false)
        setMainError("")

        axios.put("auth/register/", { email: localStorage.getItem("user_email") }).then(res => {
            console.log(res)
            setLoading(false)
            setResent(true)
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setMainError("Failed to resend token")
        })
    }

    const RegisterVerify = async () => {
        setLoading(true)
        axios.post("auth/verify_token/", {
            user_email: localStorage.getItem("user_email"),
            token: otp.join('').replace(/,/g, '')
        }).then(res => {
            // console.log(res)
            // setLoading(false)
            // window.location.href = "/login"
            console.log(res)
            setLoading(false)
            localStorage.setItem("access_token", res.data.access)
            localStorage.setItem("refresh_token", res.data.refresh)
            window.location.href = "/"
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setMainError("Token verification failed, check token and try again")
        })
    }

    return (
        <div style={{ display: "flex", height: "100vh" }}>
            <div style={{ backgroundColor: "#282828", width: "40%", display: `${window.innerWidth < 700 ? "none" : "flex"}`, flexDirection: "column", justifyContent: "space-between", padding: "58px 0 0 86px" }}>
                <svg width="123" height="26" viewBox="0 0 123 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M113.191 7.9734H114.826V26H113.191V7.9734Z" fill="white" />
                    <path d="M105.018 0.866661C105.018 0.388017 105.42 0 105.917 0H122.101C122.597 0 123 0.388018 123 0.866663V9.0133C123 9.49195 122.597 9.87996 122.101 9.87996H105.917C105.42 9.87996 105.018 9.49195 105.018 9.0133V0.866661Z" fill="#19F256" />
                    <path d="M27.1165 21.1996H27.5315V25.9996H27.1165V21.1996Z" fill="white" />
                    <path d="M25.8701 20.7999H28.9133V22.6239H25.8701V20.7999Z" fill="#19F256" />
                    <path d="M9.81842 1.03999H15.5908V25.9999H9.81842V16.2759H5.77237V25.9999H0V1.03999H5.77237V10.7639H9.81842V1.03999Z" fill="white" />
                    <path d="M29.9463 1.03999L37.391 25.9999H31.4028L27.0331 11.4399L22.6634 25.9999H16.6752L24.1739 1.03999H29.9463Z" fill="white" />
                    <path d="M52.8625 1.03999H58.5809V25.9999H52.8086V13.5199L49.7875 20.1239H47.252L44.2309 13.5199V25.9999H38.4586V1.03999H44.2309L48.5467 10.3999L52.8625 1.03999Z" fill="white" />
                    <path d="M75.1474 1.03999H80.8658V25.9999H75.0935V13.5199L72.0724 20.1239H69.5369L66.5158 13.5199V25.9999H60.7435V1.03999H66.5158L70.8316 10.3999L75.1474 1.03999Z" fill="white" />
                    <path d="M95.2205 1.03999L102.665 25.9999H96.677L92.3073 11.4399L87.9376 25.9999H81.9494L89.4481 1.03999H95.2205Z" fill="white" />
                </svg>
                <img src="https://res.cloudinary.com/ddlupbcws/image/upload/v1712065926/Group_ey2jov.webp" alt="" style={{ width: "440.64px", height: "465.97px", marginBottom: "50px" }} />
            </div>

            {window.location.search !== "?otp" && <div style={{ backgroundColor: "#F5F5F5", width: `${window.innerWidth < 700 ? "100%" : "60%"}`, padding: `${window.innerWidth < 700 ? "50px 16px" : "100px 72px"}` }}>
                <div style={{ backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "30px", padding: `${window.innerWidth < 700 ? "10px 10px" : "0"}` }}>


                    <h1 style={{ fontWeight: 700, fontSize: "40px" }}>Sign up</h1>
                    <p style={{ marginTop: 0, textAlign: "center" }}>Already registered? <a style={{ color: "#00BA77", textDecoration: "underline", cursor: "pointer" }} href="/login">Sign in</a> to your account.</p>

                    <p className='error'>{mainError}</p>
                    <div style={{ display: "flex", flexDirection: "column", gap: "16px", marginTop: "16px", }} >
                        <div style={{ display: "flex", justifyContent: "space-between", gap: "16px", flexDirection: `${window.innerWidth < 700 ? "column" : "row"}` }}>
                            <div style={{ display: "flex", flexDirection: "column", width: `"${window.innerWidth < 700 ? "100%" : "48%"}` }}>
                                <label htmlFor="">First name*</label>
                                <input style={{ padding: "16px", outline: "none", border: "1px solid #E9E9E9", borderRadius: "5px" }} onChange={(e) => {
                                    setEmailError("")
                                    setPasswordError("")
                                    setLoading(false)
                                    setFError("")
                                    setLError("")
                                    setMainError("")
                                    setRegister({ ...register, firstname: e.target.value })
                                }} type="text" />
                                <p className='error'>{fError}</p>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", width: `"${window.innerWidth < 700 ? "100%" : "48%"}` }}>
                                <label htmlFor="">Last name*</label>
                                <input style={{ padding: "16px", outline: "none", border: "1px solid #E9E9E9", borderRadius: "5px" }} onChange={(e) => {
                                    setEmailError("")
                                    setPasswordError("")
                                    setLoading(false)
                                    setFError("")
                                    setLError("")
                                    setMainError("")
                                    setRegister({ ...register, lastname: e.target.value })
                                }} type="text" />
                                <p className='error'>{lError}</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="">Email address*</label>
                            <input style={{ padding: "16px", outline: "none", border: "1px solid #E9E9E9", borderRadius: "5px" }} onChange={(e) => {
                                setEmailError("")
                                setPasswordError("")
                                setLoading(false)
                                setFError("")
                                setLError("")
                                setMainError("")
                                setRegister({ ...register, email: e.target.value })
                            }} type="text" />
                            <p className='error'>{emailError}</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="">Password*</label>
                            <div style={{ border: "1px solid #E9E9E9", display: "flex", alignItems: "center", borderRadius: "5px", paddingRight: "10px" }}>
                                <input style={{ border: "none", padding: "16px", width: "90%", outline: "none" }} onChange={(e) => {
                                    setEmailError("")
                                    setPasswordError("")
                                    setLoading(false)
                                    setFError("")
                                    setLError("")
                                    setMainError("")
                                    setRegister({ ...register, password: e.target.value })
                                }} type={viewPassword ? "text" : "password"} />
                                {viewPassword ? <svg width="24" height="24" onClick={() => setViewPassword(false)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.53 9.47L9.47004 14.53C8.82004 13.88 8.42004 12.99 8.42004 12C8.42004 10.02 10.02 8.42 12 8.42C12.99 8.42 13.88 8.82 14.53 9.47Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M17.82 5.77C16.07 4.45 14.07 3.73 12 3.73C8.46997 3.73 5.17997 5.81 2.88997 9.41C1.98997 10.82 1.98997 13.19 2.88997 14.6C3.67997 15.84 4.59997 16.91 5.59997 17.77" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8.42004 19.53C9.56004 20.01 10.77 20.27 12 20.27C15.53 20.27 18.82 18.19 21.11 14.59C22.01 13.18 22.01 10.81 21.11 9.4C20.78 8.88 20.42 8.39 20.05 7.93" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9.47 14.53L2 22" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22 2L14.53 9.47" stroke="#0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg> : <svg onClick={() => setViewPassword(true)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><g fill="#000"><path d="M128 56c-80 0-112 72-112 72s32 72 112 72s112-72 112-72s-32-72-112-72m0 112a40 40 0 1 1 40-40a40 40 0 0 1-40 40" opacity=".2" /><path d="M247.31 124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57 61.26 162.88 48 128 48S61.43 61.26 36.34 86.35C17.51 105.18 9 124 8.69 124.76a8 8 0 0 0 0 6.5c.35.79 8.82 19.57 27.65 38.4C61.43 194.74 93.12 208 128 208s66.57-13.26 91.66-38.34c18.83-18.83 27.3-37.61 27.65-38.4a8 8 0 0 0 0-6.5M128 192c-30.78 0-57.67-11.19-79.93-33.25A133.47 133.47 0 0 1 25 128a133.33 133.33 0 0 1 23.07-30.75C70.33 75.19 97.22 64 128 64s57.67 11.19 79.93 33.25A133.46 133.46 0 0 1 231.05 128c-7.21 13.46-38.62 64-103.05 64m0-112a48 48 0 1 0 48 48a48.05 48.05 0 0 0-48-48m0 80a32 32 0 1 1 32-32a32 32 0 0 1-32 32" /></g></svg>}
                            </div>
                            <p className='error'>{passwordError}</p>
                        </div>
                        {/* <div className='signup_input_referral_div'>
                        <label htmlFor="">Have a referral code?</label>
                        <input onChange={(e) => {
                            setEmailError("")
                            setLoading(false)
                            setPasswordError("")
                            setFError("")
                            setLError("")
                            setMainError("")
                            setRegister({ ...register, ref_code: e.target.value })
                        }} className='signup_input' type="text" />
                    </div> */}
                        <button style={{ backgroundColor: "#00BA77", border: "none", color: "white", borderRadius: "5px", padding: "19px 0" }} disabled={loading} onClick={Register} >{loading ? "Please wait..." : "Sign up"}</button>
                        <div style={{ width: "330px", margin: "10px auto" }}>
                            <input className='check_box' type="checkbox" />
                            <span className='check_box_text'>I accept that I am 18 years of age or older, I agree to the <span style={{ color: "#00BA77", textDecoration: "underline" }}>User Agreement,</span> and I have read the <span style={{ color: "#00BA77", textDecoration: "underline" }}>Privacy Policy</span></span>
                        </div>
                    </div>
                </div>
            </div>}

            {window.location.search === "?otp" && <div style={{ backgroundColor: "#F5F5F5", width: `${window.innerWidth < 700 ? "100%" : "60%"}`, padding: `${window.innerWidth < 700 ? "189px 16px" : "189px 72px"}` }}>
                <div style={{ backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "30px" }}>

                    <h1 style={{ fontWeight: 700, fontSize: "45px" }}>OTP</h1>
                    <p style={{ marginTop: 0, fontSize: `${window.innerWidth ? "14px" : "16px"}`, textAlign: "center", padding: `${window.innerWidth < 700 ? "0 5px" : "0 0"}` }}>One-Time Password (OTP) has been sent to your email or your authentication app.</p>
                    {resent && <p className='signup_para'>Check email for resent token</p>}
                    <p className='error'>{mainError}</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                        {otp.map((data, i) => (
                            <input key={i} style={{ width: `${window.innerWidth < 800 ? "35px" : "55px"}`, height: `${window.innerWidth < 800 ? "40px" : "60px"}`, outlineColor: "#00BA77", textAlign: "center", fontSize: "32px", fontWeight: 500 }} value={data} maxLength={1} type='text' onChange={(e) => {
                                setEmailError("")
                                setPasswordError("")
                                setLoading(false)
                                setFError("")
                                setLError("")
                                setMainError("")
                                handleChange(e, i)
                            }} />
                        ))}
                    </div>
                    <button disabled={loading} style={{ backgroundColor: "#00BA77", border: "none", color: "white", borderRadius: "5px", padding: "19px 0", width: "50%", marginTop: "16px" }} onClick={RegisterVerify}>{loading ? "Please wait..." : "Verify"}</button>
                    <p onClick={() => {
                        !loading && ResendToken()
                    }} className='check_box_text'>Didn't receive pin? <span style={{ color: "#00BA77", textDecoration: "underline", cursor: "pointer" }}>{loading && !resent ? "Please wait..." : "Resend"}</span></p>
                </div>
            </div>}
        </div>
    )
}

export default Signup