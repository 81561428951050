/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./changepassword.css";
import axios from "../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = () => {
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "black",
        color: "white"
      }
    });
  };

  const changePassword = async () => {
    setLoading(true)
    let deviceType = window.innerWidth <= 768 ? "Mobile" : window.innerWidth <= 1024 ? "Tablet" : "Desktop"
    try {
      axios.post("auth/update-password-dashboard/", {
        current_password: passwords.currentPassword,
        new_password1: passwords.newPassword,
        new_password2: passwords.confirmPassword,
        device_type:deviceType
      }).then(res => {
        console.log(res)
        setLoading(false)
        showRequestSuccess("Successfully changed password.")

      }).catch(err => {
        console.log(err)
        setLoading(false)
        if (err.response.data.detail === 'new_password1 and new_passowrd2 must match') {
          setError("New passwords do not match")
        } else if (err.response.data.detail === 'current_password is wrong') {
          setError("Current password is incorrect")
        } else {
          showRequestFailed("Failed to change password.")
        }
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }


  return (
    // <div className="rounded-card">
    <div className="changePasswordCard">
      <ToastContainer />
      <div className="changePasswordHeading">
        <h3>Change password</h3>
      </div>
      <div className="changePasswordBottom">
        <div className="currentPassword">
          <p>Current Password</p>
          <input onChange={
            (e) => {
              setError("")
              setPasswords({ ...passwords, currentPassword: e.target.value })
            }
          } type="password" />
        </div>
        <div className="newPassword">
          <p>New Password</p>
          <input type="password" onChange={(e) => {
            setError("")
            setPasswords({ ...passwords, newPassword: e.target.value })
          }
          } />
        </div>
        <div className="confirmPassword">
          <p>Confirm Password</p>
          <input type="password" onChange={(e) => {
            setError("")
            setPasswords({ ...passwords, confirmPassword: e.target.value })
          }
          } />
        </div>
        <p style={{ textAlign: "center", color: "red" }}>{error}</p>
        <div onClick={changePassword} className="saveChangesButton" style={{ cursor: "pointer" }}>
          <a>{loading ? "Changing..." : "Save changes"}</a>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default ChangePassword;
