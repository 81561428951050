import React from 'react'
import ApexCharts from 'react-apexcharts'

function BarChartHome({data, start}) {

    // function getCurrentDate() {
    //     // Get the current date
    //     const currentDate = new Date();

    //     // Get the current month
    //     let currentMonth = currentDate.getMonth();
       
    //     // Array of month names
    //     const monthNames = [
    //         "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    //     ];

    //     const lo = monthNames[currentMonth] + ", " + currentDate.getDate();

    //     // Return the name of the future month
    //     return [lo]
    // }
    console.log(data)
    let main = data * -1
    let main2 = data.toLocaleString()

    const settings = {
        series: [{
            name: 'Unsaved',
            data: [main]
        }],
        options: {
            legend: {
                show: true
            },
            chart: {
                height: 550,
                type: 'bar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            title: {
                text: `N ${main2}`,
                align: 'center'
            },
            colors: data < 0 ? [
                "#d62728"
            ] : ["#09CB09"],
            xaxis: {
                show: true,
                type: 'category',
                categories: ["Floating Profit & Loss"],
                // tickAmount: 'dataPoints',
                // tickPlacement: 'on',
                // categories: ["Jan 23", "Apr 22"]
            },
            yaxis: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
            grid: {

                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    },
                }
            },

        },


    };
    return (
        <ApexCharts options={settings.options} series={settings.series} type="bar" height={"100%"} width={"100%"} />
    )
}

export default BarChartHome