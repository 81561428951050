/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./addfunds.css";
import BankTransferImg from "../../assets/images/Group 259.svg";
import PayStackImg from "../../assets/images/Group 263.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const AddFunds = () => {
  return (
    <div className="rounded-card-container">
      <Helmet>
        <script src="//fw-cdn.com/11595992/4245246.js" chat="true"></script>
        <script>
          {`
            window.fwSettings = {
              widget_id: 153000001831,
            };
            !(function () {
              if ("function" != typeof window.FreshworksWidget) {
                var n = function () {
                  n.q.push(arguments);
                };
                (n.q = []), (window.FreshworksWidget = n);
              }
            })();
          `}
        </script>
        <script
          type="text/javascript"
          src="https://widget.freshworks.com/widgets/153000001831.js"
          async
          defer
        ></script>
      </Helmet>
      <div className="rounded-card">
        <div className="addFundDiv">
          <div className="addFundsText">
            <h2>Add Funds</h2>
          </div>
          <div className="addFundsBottom">
            <Link to="/wallet/addfunds/banktransfer">
              <div className="bankTransferCard">
                <img src={BankTransferImg} alt="BankTransfer" />
                <a href="#">Bank Transfer</a>
                <p style={{ textAlign: "center" }}>
                  Fund your Hamma account via bank transfer.
                </p>
              </div>
            </Link>
            <Link to="/wallet/addfunds/paystack">
              <div className="payStackCard">
                <img src={PayStackImg} alt="Pay stack" />
                <p style={{ textAlign: "center" }}>
                  Fund your Hamma account via paystack.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFunds;
