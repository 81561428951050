import React from 'react'
import ApexCharts from 'react-apexcharts'

function AreaChartLockupNew({months, save, final, max}) {
    console.log(months, save, final)

    function getFutureMonth(monthsAhead) {
        // Get the current date
        const currentDate = new Date();

        // Get the current month
        let currentMonth = currentDate.getMonth();

        // Calculate the future month
        let futureMonth = (currentMonth + monthsAhead) % 12;

        // Adjust for negative values
        if (futureMonth < 0) {
            futureMonth += 12;
        }

        // Array of month names
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const mo = monthNames[futureMonth] + (currentDate.getDate() - 1);
        const lo = monthNames[currentMonth] + currentDate.getDate();

        // Return the name of the future month
        return [lo, mo]
    }

    // Example usage:
    const futureMonth = getFutureMonth(3);
    console.log(futureMonth);

    const settings = {
        series: [{
            name: 'Unsaved',
            data: [save, save]
        }, {
            name: 'Saved',
            data: [save, final]
        }],
        options: {
            legend: {
                show: true
            },
            chart: {
                height: 550,
                type: 'area',
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            // title: {
            //     text: 'Distibuted Treemap (different color for each cell)',
            //     align: 'center'
            // },
            // colors: [
            //     "#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd",
            //     "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf",
            //     "#aec7e8", "#ffbb78", "#98df8a", "#ff9896", "#c5b0d5",
            //     "#c49c94", "#f7b6d2", "#c7c7c7", "#dbdb8d", "#9edae5",
            //     "#393b79", "#637939", "#8c6d31", "#843c39", "#7b4173",
            //     "#5254a3", "#637939", "#8c6d31", "#843c39", "#7b4173",
            //     "#5254a3", "#393b79", "#637939", "#8c6d31", "#843c39",
            //     "#7b4173", "#5254a3", "#393b79", "#637939", "#8c6d31",
            //     "#843c39", "#7b4173", "#5254a3", "#393b79", "#637939",
            //     "#8c6d31", "#843c39", "#7b4173", "#5254a3", "#393b79"
            // ],
            xaxis: {
                type: 'category',
                categories: getFutureMonth(months),
                tickAmount: 'dataPoints',
                // tickPlacement: 'on',
                // categories: ["Jan 23", "Apr 22"]
            },
            yaxis: {
                show: true,
                // stepSize:3,
                tickAmount:2,
                max: max,
                labels: {
                    formatter: (value) => { return `N ${Math.round(value)}` },
                },
            },
            tooltip: {
                enabled: false,
            },
            grid: {

                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                }
            },

        },


    };
    return (
        <ApexCharts options={settings.options} series={settings.series} type="area" height={"100%"} width={"100%"} />
    )
}

export default AreaChartLockupNew